import React, { useEffect } from 'react';

import { authenticationService } from '_services';

import { FormProvider, useForm } from 'react-hook-form';
import { useTypedSearchParams } from '../utils/useTypedSearchParams';
import { Input } from '@mui/material';
import { useNavigate } from 'react-router-dom';

type AuthParams = {
	login: string;
	password: string;
};

function LoginPage() {
	const navigate = useNavigate();
	const [searchParam] = useTypedSearchParams<AuthParams>();
	const methods = useForm<AuthParams>({
		mode: 'onChange',
		defaultValues: { ...searchParam },
	});

	useEffect(() => {
		navigate('/');
	}, []);

	const onSubmit = ({ login, password }: AuthParams) => {
		authenticationService.login(login, password).then(
			(user) => {
				navigate('/');
			},
			(error) => {},
		);
	};
	return (
		<>
			<h2>Kohinga Admin</h2>

			<FormProvider {...methods}>
				<div className='form-group'>
					<label htmlFor='login'>login</label>
					<br />
					<Input type='text' {...methods.register('login', { required: true })} />
				</div>
				<div className='form-group'>
					<label htmlFor='password'>Password</label>
					<br />
					<Input type='password' {...methods.register('password', { required: true })} />
				</div>
				<div className='form-group'>
					<button
						type='submit'
						className='btn btn-primary'
						onClick={(event) => onSubmit(methods.getValues())}>
						Login
					</button>
				</div>
			</FormProvider>
		</>
	);
}

export default LoginPage;
