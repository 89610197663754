import React from 'react';
import { Box, Card, Divider, Typography } from '@mui/material';

export default function Wrapper(props) {
	return (
		<Card sx={{ padding: 1, marginBottom: 2 }} variant={'outlined'}>
			{props.sectionHeading && (
				<>
					<Typography variant={'caption'} sx={{ fontWeight: 'bold' }}>
						{props.sectionHeading}
					</Typography>
					<Divider sx={{ mb: 1 }} />
				</>
			)}
			<Box>{props.children}</Box>
		</Card>
	);
}
