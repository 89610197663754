import { api } from '_services';
import { dashboardResult } from './dashboard.result';

export const dashboardService = {
	dashboard(since: Number = 0): Promise<dashboardResult> {
		return api.call('GET', '/admin/dashboard/?since=' + since).then((json) => {
			return json.data;
		});
	},
};
