import { Autocomplete, CircularProgress, Select, TextField } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useAuthorsSearch } from './use-authors-search';
import { useDebouncedCallback } from 'use-debounce';
import { Author, useAuthor, useAuthors } from './use-authors';
import { formatAuthorName } from './AuthorUtiliy';
import { Controller, UseControllerProps, useFormContext, UseFormReturn } from 'react-hook-form';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form/dist/types';
import MenuItem from '@mui/material/MenuItem';
import { useAuthorsRoles } from './use-authors-roles';

interface AuthorSelectFieldProps<T extends FieldValues> extends UseControllerProps<T> {
	selectedRoleId?: number;
}

export const AuthorRoleSelectField = <T extends FieldValues>({
	selectedRoleId,
	...registers
}: AuthorSelectFieldProps<T>) => {
	const { data: roles, isLoading } = useAuthorsRoles();

	const options = useMemo(() => {
		if (roles?.data) {
			return roles?.data;
		}
		return [];
	}, [roles]);

	return (
		<Controller
			{...registers}
			render={({ field: { value, onChange } }) => (
				<Select
					fullWidth
					value={value}
					onChange={(event) => {
						onChange(event.target.value);
					}}>
					{options.map((v) => (
						<MenuItem key={v.id} value={v.id}>
							{v.name}
						</MenuItem>
					))}
				</Select>
			)}
		/>
	);
};
