import { useDuplicationAll } from './use-duplication';
import Wrapper from '../../components/Wrapper';
import ImageList from '../book/series/ImageList';
import React, { ReactNode, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { ImagesDto } from '../../types/global';
import Button from '@mui/material/Button';
import { api } from '../../_services';
import { FormProvider, useForm } from 'react-hook-form';
import { useSyncFormWithUrl } from '../../utils/useSyncFormWithUrl';
import { useTypedSearchParams } from '../../utils/useTypedSearchParams';
import Loader from '../../components/Loader';

export type DuplicationForm = {
	scorePhashMin: string;
	scorePhashMax: string;
};

type DuplicationImageHashProps = {
	src: string;
};
const DuplicationImageHash = ({ src }: DuplicationImageHashProps) => {
	const [bytes, setBytes] = useState(null);
	useEffect(() => {
		api.call('GET', src).then((response) => {
			setBytes(response.data);
		});
	}, []);

	if (!bytes) {
		return null;
	}
	return <img src={`data:image/png;base64, ${bytes}`} width={64} height={64} />;
};

type DuplicationCardProps = {
	image: ImagesDto;
	children?: ReactNode;
};
const DuplicationCard = ({ image, children }: DuplicationCardProps) => {
	const [display, setDisplay] = useState(false);
	return (
		<Box sx={{ display: 'flex', padding: 0.5 }}>
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				<ImageList height={120} width={90} image={image} />
				{image.width} x {image.height}
				<div>id = {image.id}</div>
				{children}
				{display && (
					<>
						percep:
						<DuplicationImageHash
							src={
								'/admin/images/duplications/fromhash/perceptiveHash/' +
								image.hashPerceptual +
								'/'
							}
						/>
					</>
				)}
				<Button
					size='small'
					onClick={() => setDisplay(true)}
					variant='contained'
					disableElevation
					color='secondary'>
					show
				</Button>
			</Box>
		</Box>
	);
};

export const Duplications = () => {
	const [searchParam] = useTypedSearchParams<DuplicationForm>({
		scorePhashMin: '0',
		scorePhashMax: '1',
	});
	const { data: duplications, isLoading, refetch } = useDuplicationAll(searchParam);

	const methods = useForm<DuplicationForm>({
		mode: 'onChange',
		defaultValues: {
			scorePhashMin: searchParam.scorePhashMin ?? '0',
			scorePhashMax: searchParam.scorePhashMax ?? '1',
		},
	});
	useSyncFormWithUrl(methods.watch);

	return (
		<>
			<Wrapper>
				<FormProvider {...methods}>
					<div>
						<label>
							score phash min:
							<input
								type='text'
								size={3}
								className='form-control'
								{...methods.register('scorePhashMin')}
							/>{' '}
							max :
							<input
								type='text'
								size={3}
								className='form-control'
								{...methods.register('scorePhashMax')}
							/>
						</label>
					</div>
				</FormProvider>
			</Wrapper>

			{isLoading && <Loader />}

			{duplications?.data?.map((dup) => {
				return (
					<Wrapper key={dup.imageSrc.id}>
						<Box sx={{ display: 'flex', padding: 0.5, overflow: 'auto' }}>
							<DuplicationCard image={dup.imageSrc}>
								<div style={{ height: '150px' }}>
									<Button
										size='small'
										onClick={() =>
											api
												.call(
													'PUT',
													`/admin/images/duplications/${dup.imageSrc.id}/fusion/`,
												)
												.then(refetch)
										}
										variant='contained'
										disableElevation
										color='primary'>
										Principale
									</Button>
								</div>
							</DuplicationCard>

							{dup.imagesDst.map((dst) => {
								return (
									<DuplicationCard key={dst.id} image={dst.image}>
										<div style={{ height: '150px' }}>
											<div>per={dst.hashPerceptualScore}</div>

											<Button
												size='small'
												onClick={() =>
													api
														.call(
															'PUT',
															`/admin/images/duplications/${dup.imageSrc.id}/fusion/${dst.image.id}/`,
														)
														.then(refetch)
												}
												variant='contained'
												disableElevation
												color='primary'>
												Principale
											</Button>
											<br />
											<Button
												size='small'
												onClick={() =>
													api
														.call(
															'PUT',
															`/admin/images/duplications/${dup.imageSrc.id}/refuse/${dst.image.id}/`,
														)
														.then(refetch)
												}
												variant='contained'
												disableElevation
												color='secondary'>
												Refuser
											</Button>
										</div>
									</DuplicationCard>
								);
							})}
						</Box>
					</Wrapper>
				);
			})}
		</>
	);
};
