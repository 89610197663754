import { Box, Button } from '@mui/material';
import React from 'react';
import Loader from './Loader';

export interface QueryNextPageProps {
	hasNextPage?: boolean;
	isFetchingNextPage: boolean;
	fetchNextPage: () => void;
}

export default function QueryNextPage({
	hasNextPage = false,
	isFetchingNextPage,
	fetchNextPage,
}: QueryNextPageProps) {
	if (!hasNextPage) {
		return null;
	}

	return (
		<>
			<Box sx={{ paddingY: 4, display: 'flex', justifyContent: 'center' }}>
				{isFetchingNextPage ? (
					<Loader />
				) : (
					<Button onClick={() => fetchNextPage()}>Charger plus</Button>
				)}
			</Box>
		</>
	);
}
