import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { api, itemResult, itemService } from '_services';
import { ItemDto, ItemListDto, SearchResponse } from '../../types/global';
import { Reponse } from '../../utils/request';
import { Author } from '../book/authors/use-authors';

type UseItemProps = {
	brewery: number;
	type: number;
	overrides: string;
	search: string;
	history: any;
};
export const useItems = (
	props: UseItemProps,
	options: UseQueryOptions<Reponse<itemResult[]>> = {},
): UseQueryResult<Reponse<itemResult[]>> => {
	return useQuery<Reponse<itemResult[]>>(
		['item', 'all', JSON.stringify(props)],
		() => itemService.get(props.type, props.brewery, props.overrides, props.search),
		{
			...options,
			staleTime: 60 * 1000,
		},
	);
};

export const useItemSearch = (
	keyword: string,
	options: UseQueryOptions<Reponse<SearchResponse<ItemListDto>>> = {},
): UseQueryResult<Reponse<SearchResponse<ItemListDto>>> => {
	return useQuery<Reponse<SearchResponse<ItemListDto>>>(
		['items', keyword],
		() =>
			api.call('GET', `/search/?keyword=${keyword}`) as Promise<
				Reponse<SearchResponse<ItemListDto>>
			>,
		{
			...options,
			enabled: keyword.length > 2,
		},
	);
};
