import React, { useEffect, useState } from 'react';
import { api } from '_services';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Author, useAuthor } from './use-authors';
import { FormProvider, useForm } from 'react-hook-form';
import { formatAuthorName } from './AuthorUtiliy';
import { AuthorAutocompleteField } from './AuthorAutocompleteField';
import { DevTool } from '@hookform/devtools';
import Loader from '../../../components/Loader';
import { AuthorRoleSelectField } from './AuthorRoleSelectField';
import { useQueryClient } from 'react-query';

interface AuthorItemModalProps {
	itmId: number;
	selectedAuthorId?: number;
	selectedRoleId?: number;
	buttonTitle: string;
	onAdd: () => void;
}

type AuthorItemParam = {
	itmId: number;
	author: Author;
	roleId: number;
};

const AuthorItemModal = ({
	itmId,
	buttonTitle,
	selectedAuthorId,
	selectedRoleId,
	onAdd,
}: AuthorItemModalProps) => {
	const queryClient = useQueryClient();
	const [open, setOpen] = useState(false);
	const { data: selectedAuthor, isLoading } = useAuthor(selectedAuthorId ?? 0);

	const methods = useForm<AuthorItemParam>({
		mode: 'onChange',
		defaultValues: {
			itmId,
			roleId: selectedRoleId ?? 0,
		},
	});

	useEffect(() => {
		if (selectedAuthor?.data) {
			methods.setValue('author', selectedAuthor.data);
		}
	}, [methods, selectedAuthor]);

	if ((selectedAuthorId ?? 0) > 0 && isLoading) {
		return <Loader />;
	}

	return (
		<>
			<Button
				size='small'
				onClick={() => setOpen(true)}
				variant='contained'
				disableElevation
				color='primary'>
				{buttonTitle}
			</Button>
			<Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth={'md'}>
				<DialogTitle>
					{selectedAuthor?.data
						? `Modification pour ${formatAuthorName(selectedAuthor.data)}`
						: 'Ajouter'}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<FormProvider {...methods}>
							<form
								onSubmit={methods.handleSubmit((data) => {
									void api
										.call('POST', `/item/${itmId}/author/`, {
											authorId: data.author.id,
											roleId: data.roleId,
										})
										.then(onAdd)
										.then(async () =>
											queryClient.invalidateQueries(['authors', 'items'], {
												exact: false,
											}),
										)
										.then(() => setOpen(false));
								})}>
								<DevTool control={methods.control} placement={'top-left'} />
								<div className='form-group'>
									<AuthorAutocompleteField
										name='author'
										selectedAuthorId={selectedAuthorId}
										control={methods.control}
									/>
								</div>
								<div className='form-group'>
									<AuthorRoleSelectField
										name='roleId'
										selectedRoleId={selectedRoleId}
										control={methods.control}
									/>
								</div>
								<div className='form-group'>
									<Button
										size='small'
										variant='contained'
										disableElevation
										type='submit'
										color='primary'>
										Valider
									</Button>
								</div>
							</form>
						</FormProvider>
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default AuthorItemModal;
