import { api } from '_services';
import { itemResult } from './item.result';
import { AdminLastItem } from './itemUser.result';
import { Reponse } from '../utils/request';
import { EditionUserMedia } from '../types/global';

export const itemService = {
	get(type = 0, brewery = 0, overrides = '', search = ''): Promise<Reponse<itemResult[]>> {
		return api.call(
			'GET',
			`/admin/items/?type=${type}&brewery=${brewery}&overrides=${overrides}&search=${search}`,
		);
	},

	getLastitemAdd(): Promise<Array<AdminLastItem>> {
		return api.call('GET', '/admin/items/last/').then((json) => {
			return json.data;
		});
	},
	getUsers(id) {
		return api.call('GET', `/admin/item/${id}/users/`);
	},
	getTitles(id) {
		return api.call('GET', `/admin/item/${id}/title/`);
	},
	updTitles(id, action, usrId, title) {
		return api.call('PUT', `/admin/item/${id}/title/`, {
			action,
			usrId,
			title,
		});
	},
	getImagesUsers(id) {
		return api.call('GET', `/admin/item/${id}/images/`).then((json) => {
			return json.data;
		});
	},
	updImages(id, action, usrId, imgId) {
		return api.call('PUT', `/admin/item/${id}/images/`, {
			action,
			usrId,
			imgId,
		});
	},
	addEan(id: number, data: EditionUserMedia) {
		return api.call('PUT', `/admin/item/${id}/edition/`, data);
	},
	deleteEan(id: number, ean: string) {
		return api.call('DELETE', `/admin/item/${id}/edition/`, { ean: ean });
	},
	updateMainImage(id: number, imgId: number) {
		return api.call('PUT', `/admin/item/${id}/image/${imgId}/`, {});
	},
	deleteMainImage(id: number) {
		return api.call('DELETE', `/admin/item/${id}/image/`);
	},
	delete(id: number) {
		return api.call('DELETE', `/admin/item/${id}/`);
	},
	searchExternal(title: string) {
		return api.call('GET', '/admin/search/external/?title=' + title).then((json) => {
			return json.data;
		});
	},
	search(keyword: string) {
		return api.call('GET', '/search/?keyword=' + keyword).then((json) => {
			return json.data;
		});
	},
	fusion(oldid: number, newid: number) {
		return api.call('PUT', `/admin/item/${oldid}/fusion/?newId=${newid}`).then((json) => {
			return json.data;
		});
	},
};
