import React from 'react';
import { Box, Button, Link, ListItem, ListItemText, Typography } from '@mui/material';
import { EditionsAdminDto } from '../../../types/global';
import ImageList from '../series/ImageList';
import { useNavigate } from 'react-router-dom';

interface EditionListProps {
	edition: EditionsAdminDto;
	onClick: () => void;
}
export default function EditionList({ edition, onClick }: EditionListProps) {
	const navigate = useNavigate();

	return (
		<ListItem onClick={onClick} sx={{ paddingX: 1 }}>
			<ImageList height={120} width={90} image={edition.image} />
			<Box>
				<Typography variant='caption' component='div'>
					{edition.item.title}
				</Typography>
				<Link
					href={'/items/detail/' + edition.item.id}
					onClick={() => navigate('/items/detail/' + edition.item.id)}
				>
					<small>Détail</small>
				</Link>
			</Box>
		</ListItem>
	);
}
