import AuthorList from '../book/authors/AuthorList';
import Wrapper from '../../components/Wrapper';
import React from 'react';
import { ItemDto } from '../../types/global';
import { api } from '../../_services';
import AuthorItemModal from '../book/authors/AuthorItemModal';
import ButtonDialog from '../../components/Widgets/ButtonDialog';
import { useAuthorItemsByAuthor, useAuthorItemsByItem } from '../book/authors/use-authors-items';
import { AuthorItemEdit } from '../book/authors/AuthorItemEdit';

type DetailAuthorsProps = {
	media: ItemDto;
	onChange: () => void;
};

export const DetailAuthors = ({ media, onChange }: DetailAuthorsProps) => {
	const { data: items, refetch: refetchItems } = useAuthorItemsByItem(media.id);

	return (
		<Wrapper sectionHeading='Auteurs'>
			{items?.data && <AuthorItemEdit data={items.data} onChange={refetchItems} />}

			<AuthorItemModal
				itmId={media.id}
				selectedAuthorId={undefined}
				selectedRoleId={undefined}
				buttonTitle={'Ajouter'}
				onAdd={onChange}
			/>
		</Wrapper>
	);
};
