import { CircularProgress, Grid, Typography } from '@mui/material';
import React from 'react';

export interface LoaderProps {
	message?: string;
	info?: string;
}
export default function Loader({ message, info }: LoaderProps) {
	return (
		<Grid
			data-testid='loader'
			container
			spacing={0}
			direction='column'
			alignItems='center'
			justifyContent='center'
			style={{ minHeight: '100px' }}
		>
			<Grid item xs={3}>
				<CircularProgress size={info ? 30 : 18} thickness={10} />

				<span>
					<Typography component='span' variant='body1'>
						{message ? message : 'Chargement en cours'}
					</Typography>
					{info && (
						<Typography
							component='span'
							variant='body1'
							style={{
								fontStyle: 'italic',
							}}
						>
							<br />
							{info}
						</Typography>
					)}
				</span>
			</Grid>
		</Grid>
	);
}
