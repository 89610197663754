import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import { ListItemButton, ListItemText, SwipeableDrawer } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import Errors from './components/Errors';

const defaultTheme = createTheme({
	typography: {
		fontSize: 12,
		h1: {
			fontSize: '1.1rem',
			fontWeight: 'bold',
		},
		caption: {
			fontWeight: 'bold',
		},
	},
	components: {
		MuiTableCell: {
			defaultProps: {
				padding: 'none',
			},
			styleOverrides: {
				root: { padding: '5px' },
			},
		},
	},
});

export default function Layout() {
	const navigate = useNavigate();
	const [open, setOpen] = React.useState(false);
	const toggleDrawer = () => {
		setOpen(!open);
	};

	return (
		<ThemeProvider theme={defaultTheme}>
			<Box sx={{ display: 'flex' }}>
				<CssBaseline />
				<IconButton
					edge='start'
					color='inherit'
					onClick={toggleDrawer}
					sx={{
						position: 'absolute',
						left: 10,
						...(open && { display: 'none' }),
					}}>
					<MenuIcon />
				</IconButton>
				<SwipeableDrawer open={open} onClose={toggleDrawer} onOpen={toggleDrawer}>
					<List component='nav'>
						{[
							{
								label: 'Home',
								to: '/',
							},
							{
								label: 'Derniers Ajouts',
								to: '/items/last',
							},
							{
								label: 'Utilisateurs',
								to: '/users/',
							},
							{
								label: 'Items',
								to: '/items',
							},
							{
								label: 'Editions surchargées',
								to: '/book/editions/overrides',
							},
							{
								label: 'Editions new',
								to: '/book/editions/new',
							},
							{
								label: 'Séries',
								to: '/book/series?overrides=true',
							},
							{
								label: 'Séries Groupement',
								to: '/book/series/group',
							},
							{
								label: 'Editeurs',
								to: '/book/editors',
							},
							{
								label: 'Auteurs',
								to: '/book/authors',
							},
							{
								label: 'Auteurs Roles',
								to: '/book/authorsRoles',
							},
							{
								label: 'Images',
								to: '/images',
							},
							{
								label: 'News',
								to: '/news',
							},
							{
								label: 'Moteur de Recherche',
								to: '/search',
							},
							{
								label: 'Requetes sql',
								to: '/sql',
							},
							{
								label: 'Notifications',
								to: '/notify',
							},
							{
								label: 'Brasseries',
								to: '/brewery',
							},
							{
								label: 'Déconnexion',
								to: '/logout',
							},
						].map((item) => {
							return (
								<>
									<Divider sx={{ my: 1 }} />
									<ListItemButton
										sx={{ py: 0 }}
										onClick={() => {
											navigate(item.to);
											setOpen(false);
										}}>
										<ListItemText primary={item.label} />
									</ListItemButton>
								</>
							);
						})}
					</List>
				</SwipeableDrawer>
				<Box
					component='main'
					sx={{
						flexGrow: 1,
						height: '100vh',
						overflow: 'auto',
						paddingY: 5,
						paddingX: 1,
					}}>
					<Errors />
					<Outlet />
				</Box>
			</Box>
		</ThemeProvider>
	);
}
