import Button from '@mui/material/Button';
import React from 'react';

interface ButtonDialogProps {
	onClick: any;
	text: string;
	className?: string | undefined | null;
	startIcon?: string | undefined | null;
}
const ButtonPrimary = (props: ButtonDialogProps) => {
	const text = props.text ?? 'Boutton';
	const className = props.className ?? 'btn';
	const startIcon = props.startIcon ?? null;

	function click() {
		if (props.onClick) {
			props.onClick();
		}
	}
	return (
		<>
			<Button
				variant='contained'
				disableElevation
				color='primary'
				size='small'
				className={className}
				startIcon={startIcon}
				onClick={() => click()}
			>
				{text}
			</Button>
		</>
	);
};
export default ButtonPrimary;
