import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Table, TableBody } from '@mui/material';
import { AuthorItemListDto, ValidationState } from '../../../types/global';
import { Link } from 'react-router-dom';
import { ValidationBloc } from '../../../components/ValidationBloc';
import React from 'react';
import { useMutationAuthorItemsUpdUserState } from './use-authors-items';
import ButtonDialog from '../../../components/Widgets/ButtonDialog';
import { api } from '../../../_services';

interface AuthorItemEditProps {
	data: AuthorItemListDto[];
	onChange: () => void;
}
export const AuthorItemEdit = ({ data, onChange }: AuthorItemEditProps) => {
	const mutationAuthorItemsUpdUserState = useMutationAuthorItemsUpdUserState();

	return (
		<Table size='small' className='table-responsive'>
			<TableHead>
				<TableRow>
					<TableCell>Auteur</TableCell>
					<TableCell>Titre</TableCell>
					<TableCell>Role</TableCell>
					<TableCell>User</TableCell>
					<TableCell></TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{data.map((itm) => {
					const isNewOne = itm.usrId && itm.userState != ValidationState.REFUSED;
					const isRefuseOne = itm.usrId && itm.userState == ValidationState.REFUSED;

					return (
						<TableRow
							key={itm.id}
							className={`${isNewOne ? 'isNewOne' : ''} ${
								isRefuseOne ? 'isRefuseOne' : ''
							}`}>
							<TableCell>
								<Link to={`/book/authors/${itm.authorId}`}>
									{itm.authorId} - {itm.authorName}
								</Link>
							</TableCell>
							<TableCell>
								<Link to={`/items/detail/${itm.id}`}>
									{itm.id} - {itm.title}
								</Link>
							</TableCell>
							<TableCell>{itm.roleName}</TableCell>
							<TableCell>{itm.usrId}</TableCell>
							<TableCell>
								{itm.usrId > 0 && (
									<ValidationBloc
										state={itm.userState}
										onValidate={() =>
											mutationAuthorItemsUpdUserState.mutate(
												{
													itmId: itm.id,
													authorId: itm.authorId,
													roleId: itm.roleId,
													state: ValidationState.ACCEPTED,
												},
												{
													onSuccess: onChange,
												},
											)
										}
										onRefuse={() =>
											mutationAuthorItemsUpdUserState.mutate(
												{
													itmId: itm.id,
													authorId: itm.authorId,
													roleId: itm.roleId,
													state: ValidationState.REFUSED,
												},
												{
													onSuccess: onChange,
												},
											)
										}
									/>
								)}

								<ButtonDialog
									text='Supprimer'
									color='error'
									onValid={() =>
										void api
											.call(
												'DELETE',
												`/item/${itm.id}/author/${itm.authorId}/role/${itm.roleId}/`,
												{},
											)
											.then(onChange)
									}
									confirmBtnCancel='Annuler'
									confirmBtnValid='Supprimer'
									confirmTitle='Souhaitez vous cet auteur ?'
								/>
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		</Table>
	);
};
