import { api, breweryResult, itemResult } from '_services';

export const breweryService = {
	find(): Promise<Array<breweryResult>> {
		return api.call('GET', '/brewery/').then((json) => {
			return json.data;
		});
	},
	getCount(): Promise<number> {
		return api.call('GET', '/admin/brewery/count/').then((json) => {
			return json.data;
		});
	},
	get(id: number): Promise<breweryResult> {
		return api.call('GET', '/brewery/' + id + '/').then((json) => {
			return json.data;
		});
	},
	add(data: breweryResult) {
		return api.call('POST', '/brewery/', data).then((json) => {
			return json.data;
		});
	},
	upd(id: number, data: breweryResult) {
		return api.call('PUT', '/brewery/' + (id > 0 ? id + '/' : ''), data).then((json) => {
			return json.data;
		});
	},
	delete(id: number) {
		return api.call('DELETE', '/brewery/' + id + '/').then((json) => {
			return json.data;
		});
	},
	getItems(id: number): Promise<Array<itemResult>> {
		return api.call('GET', '/brewery/' + id + '/item/').then((json) => {
			return json.data;
		});
	},
	uploadImg(id: number, file) {
		return api.upload('/brewery/' + id + '/image/', file);
	},
};
