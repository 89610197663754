import { authHeader } from '_helpers';
import { authenticationService } from '_services';
import PubSub from 'pubsub-js';
import axios from 'axios';

var apiUrl = '/api';

export const api = {
	call(method, url, params) {
		const requestOptions = {
			method: method,
			headers: authHeader(),
			body: typeof params === 'object' ? JSON.stringify(params) : params,
		};
		return fetch(apiUrl + url, requestOptions)
			.then((response) => {
				return response.text().then((text) => {
					if ([401, 403].indexOf(response.status) !== -1) {
						authenticationService.logout();
						return {};
					}
					if ([500].indexOf(response.status) !== -1) {
						console.log('ERREUR 500' + text);
						return {};
					}

					try {
						const data = text && JSON.parse(text);

						if (!data.result) {
							PubSub.publish('app_error', data.message);
						}

						return data;
					} catch (e) {
						PubSub.publish('app_error', e.message);
						return text;
					}
				});
			})
			.catch((e) => {
				PubSub.publish('app_error', e.message);
			});
	},
	upload(url, file) {
		let formData = new FormData();
		formData.append('file', file);

		return axios
			.post(apiUrl + url, formData, {
				headers: authHeader(true),
				onUploadProgress: function(progressEvent) {},
			})
			.then(function(response) {
				return true;
			})
			.catch((e) => {
				PubSub.publish('app_error', e.message);
			});
	},
};
