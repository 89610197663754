import {
	QueryFunctionContext,
	QueryKey,
	useInfiniteQuery,
	UseInfiniteQueryOptions,
	UseInfiniteQueryResult,
	useMutation,
	useQuery,
	UseQueryOptions,
	UseQueryResult,
} from 'react-query';
import { Reponse } from '../../../utils/request';
import { api } from '../../../_services';
import {
	EditionMedia,
	EditionsAdminDto,
	EditionUserMedia,
	EditionValidationType,
	SearchResponse,
	ValidationState,
} from '../../../types/global';

export const useEditions = (
	itemId: number,
	options: UseQueryOptions<Reponse<EditionMedia[]>> = {},
): UseQueryResult<Reponse<EditionMedia[]>> => {
	return useQuery<Reponse<EditionMedia[]>>(
		['editions', 'list', itemId],
		() =>
			api.call('GET', `/admin/editions/${itemId}/list/`) as Promise<Reponse<EditionMedia[]>>,
		{
			...options,
		},
	);
};

export const useEditionsUsers = (
	itemId: number,
	options: UseQueryOptions<Reponse<EditionUserMedia[]>> = {},
): UseQueryResult<Reponse<EditionUserMedia[]>> => {
	return useQuery<Reponse<EditionUserMedia[]>>(
		['editions', 'users', itemId],
		() =>
			api.call('GET', `/admin/editions/${itemId}/users/`) as Promise<
				Reponse<EditionUserMedia[]>
			>,
		{
			...options,
		},
	);
};

export const useEditionsOverride = (
	options: UseInfiniteQueryOptions<Reponse<SearchResponse<EditionsAdminDto>>> = {},
): UseInfiniteQueryResult<Reponse<SearchResponse<EditionsAdminDto>>> => {
	return useInfiniteQuery<Reponse<SearchResponse<EditionsAdminDto>>>(
		['editions'],
		({ pageParam = 0 }: QueryFunctionContext<QueryKey, number>) =>
			api.call('GET', `/admin/editions/overrides/?page=${pageParam / 30}`) as Promise<
				Reponse<SearchResponse<EditionsAdminDto>>
			>,
		{
			...options,
			getNextPageParam: (lastPage) => {
				return lastPage.data.nextOffset;
			},
		},
	);
};

export const useEditionsNew = (
	options: UseInfiniteQueryOptions<Reponse<SearchResponse<EditionsAdminDto>>> = {},
): UseInfiniteQueryResult<Reponse<SearchResponse<EditionsAdminDto>>> => {
	return useInfiniteQuery<Reponse<SearchResponse<EditionsAdminDto>>>(
		['editions'],
		({ pageParam = 0 }: QueryFunctionContext<QueryKey, number>) =>
			api.call('GET', `/admin/editions/new/?page=${pageParam / 30}`) as Promise<
				Reponse<SearchResponse<EditionsAdminDto>>
			>,
		{
			...options,
			getNextPageParam: (lastPage) => {
				return lastPage.data.nextOffset;
			},
		},
	);
};

interface MutationDeleteEditionsUsers {
	itemId: number;
	ean: string;
	userId: number;
}
export const useMutationDeleteEditionsUsers = () => {
	return useMutation(async (submitData: MutationDeleteEditionsUsers) => {
		return api.call(
			'DELETE',
			`/admin/editions/${submitData.itemId}/${submitData.ean}/${submitData.userId}/`,
			{},
		) as Promise<Reponse<boolean>>;
	});
};

interface MutationEditionsValidate {
	itemId: number;
	ean: string;
	userId: number;
	type: EditionValidationType;
	state: ValidationState;
}
export const useMutationEditionsValidate = () => {
	return useMutation(async (submitData: MutationEditionsValidate) => {
		return api.call(
			'PUT',
			`/admin/editions/${submitData.itemId}/${submitData.ean}/${submitData.userId}/validate/`,
			{
				type: submitData.type,
				state: submitData.state,
			},
		) as Promise<Reponse<boolean>>;
	});
};
