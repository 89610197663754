import React, { useState, useEffect } from 'react';
import { itemService } from '_services';
import Typography from '@mui/material/Typography';
import ButtonDialog from 'components/Widgets/ButtonDialog';
import EansAdd from 'pages/items/EansAdd';
import EansSearch from 'pages/items/EansSearch';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const Users = (props) => {
	const [data, setData] = useState({
		loaded: false,
		rows: [],
	});

	useEffect(() => {
		if (!data.loaded) {
			if (props.id && props.id > 0) {
				// chargement des titres
				itemService.getUsers(props.id).then((res) => {
					setData({
						loaded: true,
						rows: res.data,
					});
				});
			}
		}
	}, [data]);

	if (props.id && props.id > 0) {
		return (
			<>
				{data.rows && data.rows.length > 0 && (
					<div className='table-wrapper'>
						<Table size='small'>
							<TableHead>
								<TableRow>
									<TableCell>Id</TableCell>
									<TableCell>Name</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{data.rows.map((row) => (
									<TableRow key={row.ean}>
										<TableCell component='th' scope='row'>
											{row.id}
										</TableCell>
										<TableCell>{row.name}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</div>
				)}
			</>
		);
	} else {
		return <p>Paramètres invalide.</p>;
	}
};

export default Users;
