import { ThemeProvider } from '@mui/material';
import Logout from 'pages/Logout';
import {
	createBrowserRouter,
	createRoutesFromElements,
	Outlet,
	Route,
	RouterProvider as RouterProviderRRD,
} from 'react-router-dom';
import { authenticationService } from '_services';
import { FilterItemsProvider } from '_store/filters';
import Brewerys from './pages/brewery/Brewerys';
import BreweryDetail from './pages/brewery/Detail';
import Search from './pages/config/Search';
import Dashboard from './pages/Dashboard';
import ItemDetail from './pages/items/Detail';
import Items from './pages/items/Items';
import Login from './pages/Login';
import Notify from './pages/notify/Notify';
import ItemsLastadd from './pages/users/Lastadd';
import UsersList from './pages/users/List';
import MuiTheme from './theme';
import SeriesPage from './pages/book/series/SeriesPage';
import SeriePage from './pages/book/series/SeriePage';
import EditionsOverridesPage from './pages/book/editions/EditionsOverridesPage';
import EditionsNewPage from './pages/book/editions/EditionsNewPage';
import AuthorsPage from './pages/book/authors/AuthorsPage';
import Layout from './Layout';
import SeriesGroupPage from './pages/book/seriesGroup/SeriesGroupPage';
import SerieGroupPage from './pages/book/seriesGroup/SerieGroupPage';
import React from 'react';
import AuthorPage from 'pages/book/authors/AuthorPage';
import Sql from './pages/sql/Sql';
import AuthorsRolesPage from './pages/book/authorsRoles/AuthorsRolesPage';
import AuthorRolePage from './pages/book/authorsRoles/AuthorRolePage';
import EditorsPage from './pages/book/editors/EditorsPage';
import EditorPage from './pages/book/editors/EditorPage';
import Images from 'pages/images/Images';
import { News } from './pages/news/News';
import { NewsDetail } from './pages/news/NewsDetail';

export const AuthProvider = () => {
	if (!authenticationService.getToken()) {
		return <Login />;
	}

	return <Outlet />;
};

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route element={<AuthProvider />}>
			<Route element={<Layout />}>
				<Route path='/' element={<Dashboard />} />
				<Route path='/logout' element={<Logout />} />
				<Route path='/login' element={<Login />} />
				<Route path='/users/' element={<UsersList />} />
				<Route path='/items/' element={<Items />} />
				<Route path='/items/last' element={<ItemsLastadd />} />
				<Route path='/items/detail/:id' element={<ItemDetail />} />
				<Route path='/notify/' element={<Notify />} />
				<Route path='/brewery/' element={<Brewerys />} />
				<Route path='/brewery/detail/:id' element={<BreweryDetail />} />
				<Route path='/book/editors' element={<EditorsPage />} />
				<Route path='/book/editors/:id' element={<EditorPage />} />
				<Route path='/book/editions/new' element={<EditionsNewPage />} />
				<Route path='/book/editions/overrides' element={<EditionsOverridesPage />} />
				<Route path='/book/authorsRoles' element={<AuthorsRolesPage />} />
				<Route path='/book/authorsRoles/:id' element={<AuthorRolePage />} />
				<Route path='/book/authors' element={<AuthorsPage />} />
				<Route path='/book/authors/:id' element={<AuthorPage />} />
				<Route path='/book/series' element={<SeriesPage />} />
				<Route path='/book/series/:id' element={<SeriePage />} />
				<Route path='/book/series/group/' element={<SeriesGroupPage />} />
				<Route path='/book/series/group/:id' element={<SerieGroupPage />} />
				<Route path='/news' element={<News />} />
				<Route path='/news/:id' element={<NewsDetail />} />
				<Route path='/search/' element={<Search />} />
				<Route path='/images/' element={<Images />} />
				<Route path='/sql/' element={<Sql />} />
			</Route>
		</Route>,
	),
);

const Routes = () => {
	return (
		<ThemeProvider theme={MuiTheme}>
			<FilterItemsProvider>
				<RouterProviderRRD router={router} />
			</FilterItemsProvider>
		</ThemeProvider>
	);
};

export default Routes;
