import React, { useState } from 'react';
import Wrapper from '../../../components/Wrapper';
import QueryNextPage from '../../../components/QueryNextPage';
import { useEditionsOverride } from './use-editions';
import EditionList from './EditionList';
import EditionEdit from './EditionEdit';
import Loader from '../../../components/Loader';
import { Divider } from '@mui/material';
import PageTitle from '../../../layout-components/PageTitle';

const EditionsOverridesPage = () => {
	const [editEan, setEditEan] = useState(0);
	const {
		data: editions,
		hasNextPage,
		isLoading,
		isError,
		error,
		isFetchingNextPage,
		fetchNextPage,
	} = useEditionsOverride();

	if (isLoading) {
		return <Loader />;
	}

	if (isError) {
		return <>{error}</>;
	}

	return (
		<>
			<PageTitle titleHeading='Editions' titleDescription='surchargées' />

			<Wrapper>
				{editions?.pages.map((edt) =>
					edt.data.objects.map((value) => (
						<>
							<EditionList
								edition={value}
								onClick={() => setEditEan(value.item.id)}
							/>
							{editEan === value.item.id && <EditionEdit itemId={value.item.id} />}
							<Divider className='my-2' />
						</>
					)),
				)}
				<QueryNextPage
					hasNextPage={hasNextPage}
					isFetchingNextPage={isFetchingNextPage}
					fetchNextPage={fetchNextPage}
				/>
			</Wrapper>
		</>
	);
};

export default EditionsOverridesPage;
