import React, { useState } from 'react';
import { api } from '_services';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import InputOnChange from '../../../components/InputOnChange';
import { EditorDto } from './use-editors';

interface EditorSearchModalProps {
	editor?: EditorDto;
	buttonTitle: string;
	onAdd: (authorId: number) => void;
}
const EditorSearchModal = ({ buttonTitle, editor, onAdd }: EditorSearchModalProps) => {
	const [open, setOpen] = useState(false);
	const [data, setData] = useState<EditorDto[]>([]);

	return (
		<>
			<Button
				size='small'
				onClick={() => setOpen(true)}
				variant='contained'
				disableElevation
				color='primary'>
				{buttonTitle}
			</Button>
			<Dialog open={open} onClose={() => setOpen(false)} maxWidth={false}>
				<DialogTitle>
					{editor ? (
						<>
							FUSION pour {editor.id} - {editor.name}
						</>
					) : (
						'Ajouter'
					)}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<InputOnChange
							name='Search'
							type='text'
							onValueChange={(keyword) => {
								void api
									.call('GET', `/editor/search/?keyword=${keyword}`)
									.then((response) => {
										setData(response.data.objects as EditorDto[]);
									});
							}}
						/>

						<div className='table-wrapper'>
							<Table size='small'>
								<TableBody>
									{data &&
										data.map((r) => {
											if (r.id != editor?.id) {
												return (
													<TableRow key={r.id}>
														<TableCell>{r.id}</TableCell>
														<TableCell>{r.name}</TableCell>
														<TableCell>
															<Button
																onClick={() => {
																	onAdd(r.id);
																	setOpen(false);
																}}
																size='small'
																variant='contained'
																disableElevation
																color='primary'>
																Sélectionner
															</Button>
														</TableCell>
													</TableRow>
												);
											}
										})}
								</TableBody>
							</Table>
						</div>
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default EditorSearchModal;
