import React, { useState, useEffect } from 'react';
import { itemService } from '_services';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { getListImg } from '_helpers';

const EansSearch = (props) => {
	const [data, setData] = useState({
		open: false,
		results: [],
	});

	if (props.id && props.id > 0) {
		function handleClose() {
			setData({ ...data, open: false });
		}

		function addEan(ean) {
			itemService.addEan(props.id, { ean } ).then((res) => {
				props.onUpd();
			});
		}

		return (
			<>
				<Button
					size='small'
					onClick={() => setData({ ...data, open: true })}
					variant='contained'
					disableElevation
					color='primary'
				>
					Rechercher
				</Button>
				<Dialog open={data.open} onClose={handleClose}>
					<Formik
						initialValues={{
							title: props.title,
						}}
						validationSchema={Yup.object().shape({
							title: Yup.string().required('title is required'),
						})}
						onSubmit={({ title }, { setStatus, setSubmitting }) => {
							itemService.searchExternal(title).then((res) => {
								setData({
									...data,
									open: true,
									results: res,
								});
								setSubmitting(false);
							});
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
						}) => (
							<Form>
								<DialogTitle>Recherche ...</DialogTitle>
								<DialogContent>
									<DialogContentText>
										<Field
											name='title'
											type='text'
											className={
												'form-control' +
												(errors.title && touched.title ? ' is-invalid' : '')
											}
										/>
										<ErrorMessage
											name='title'
											component='div'
											className='invalid-feedback'
										/>

										{data.results &&
											data.results.map((r) => {
												return (
													<div>
														{r.images.map((img) => {
															return (
																<img src={getListImg(img.path)} />
															);
														})}

														{r.title}

														<Button
															onClick={() =>
																addEan(r.editions[0].ean)
															}
															color='primary'
															type='submit'
														>
															Add= {r.editions[0].ean}
														</Button>
													</div>
												);
											})}
									</DialogContentText>
								</DialogContent>
								<DialogActions>
									<Button onClick={handleClose} color='primary'>
										Fermer
									</Button>
									<Button
										onClick={handleSubmit}
										disabled={isSubmitting}
										color='primary'
										type='submit'
									>
										Recherche
									</Button>
								</DialogActions>
							</Form>
						)}
					</Formik>
				</Dialog>
			</>
		);
	} else {
		return <p>Paramètres invalide.</p>;
	}
};

export default EansSearch;
