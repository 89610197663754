import { api } from '_services';

export const notifyService = {
	push(title: string, desc: string, link: string, usrId: number): Promise<Array<boolean>> {
		return api
			.call('PUT', '/admin/notification/send/', {
				title: title,
				desc: desc,
				link: link,
				usrId: usrId,
			})
			.then((json) => json.data);
	},
};
