import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Box, OutlinedInput } from '@mui/material';
import { ItemDto, ItemListDto, NewsDetailLinkDto, SeriesDto } from '../../types/global';
import { useSearchSerie } from '../book/series/use-series';
import Wrapper from '../../components/Wrapper';
import SerieList from '../book/series/SerieList';
import Loader from '../../components/Loader';
import { api } from '../../_services';
import { useItemSearch } from '../items/use-items';

interface SerieGroupAddProps {
	news: NewsDetailLinkDto;
	onChange?: () => void;
}

const NewsItemsAdd = ({ news, onChange }: SerieGroupAddProps) => {
	const [openModalSearch, setOpenModalSearch] = useState<ItemListDto>();
	const [text, setText] = useState('');

	const { data: search, isLoading } = useItemSearch(text);

	return (
		<Wrapper sectionHeading='Ajouter un item'>
			<OutlinedInput
				fullWidth
				type='text'
				className='form-control'
				onChange={(t) => {
					setText(t.target.value);
				}}
			/>
			{isLoading && <Loader />}
			{search?.data?.objects
				.filter((s) => !news.links.items.map((sg) => sg.id).includes(s.id))
				.map((s) => (
					<Box key={s.id}>
						<Button onClick={() => setOpenModalSearch(s)}>{s.title}</Button>
					</Box>
				))}

			<Dialog
				open={!!openModalSearch}
				onClose={() => setOpenModalSearch(undefined)}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'>
				<DialogTitle id='alert-dialog-title'>Ajouter l'item ?</DialogTitle>
				<DialogActions>
					<Button onClick={() => setOpenModalSearch(undefined)} color='primary'>
						Annuler
					</Button>
					<Button
						onClick={() =>
							api
								.call(
									'PUT',
									`/admin/news/${news.news.id}/item/${openModalSearch?.id}/`,
								)
								.then(() => {
									if (onChange) {
										onChange();
									}
									setOpenModalSearch(undefined);
								})
						}
						color='primary'>
						Ajouter
					</Button>
				</DialogActions>
			</Dialog>
		</Wrapper>
	);
};

export default NewsItemsAdd;
