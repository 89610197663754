import React, { useState, useEffect } from 'react';
import { itemService } from '_services';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const EansAdd = (props) => {
	const [data, setData] = useState({
		open: false,
	});

	if (props.id && props.id > 0) {
		function handleClose() {
			setData({ ...data, open: false });
		}

		return (
			<>
				<Button
					size='small'
					onClick={() => setData({ ...data, open: true })}
					variant='contained'
					disableElevation
					color='primary'>
					Ajouter
				</Button>
				<Dialog open={data.open} onClose={handleClose}>
					<Formik
						initialValues={{
							ean: '',
						}}
						validationSchema={Yup.object().shape({
							ean: Yup.string().required('ean is required'),
						})}
						onSubmit={({ ean }, {}) => {
							void itemService.addEan(props.id, { ean }).then(() => {
								handleClose();
								props.onUpd();
							});
						}}>
						{({ errors, touched, handleSubmit, isSubmitting }) => (
							<Form>
								<DialogTitle>Ajouter un EAN</DialogTitle>
								<DialogContent>
									<DialogContentText>
										<Field
											name='ean'
											type='text'
											className={
												'form-control' +
												(errors.ean && touched.ean ? ' is-invalid' : '')
											}
										/>
										<ErrorMessage
											name='ean'
											component='div'
											className='invalid-feedback'
										/>
									</DialogContentText>
								</DialogContent>
								<DialogActions>
									<Button onClick={handleClose} color='primary'>
										Fermer
									</Button>
									<Button
										onClick={handleSubmit}
										disabled={isSubmitting}
										color='primary'
										type='submit'>
										Ajouter
									</Button>
								</DialogActions>
							</Form>
						)}
					</Formik>
				</Dialog>
			</>
		);
	} else {
		return <p>Paramètres invalide.</p>;
	}
};

export default EansAdd;
