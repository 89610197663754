import React, { useState, useEffect } from 'react';
import { api, itemService } from '_services';

import { getListImg } from '_helpers';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ValidationBloc } from '../../components/ValidationBloc';
import { ImagesDto, ItemUserImagesDto, SeriesDto, ValidationState } from '../../types/global';
import { useQuery } from 'react-query';
import { Reponse } from '../../utils/request';
import ButtonDialog from '../../components/Widgets/ButtonDialog';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

type DetailImagesProps = {
	itmId: number;
	mainImages: ImagesDto[];
	onImagesUpd: () => void;
};

export const DetailImagesUsers = ({ itmId, onImagesUpd, mainImages }: DetailImagesProps) => {
	const {
		data: images,
		isLoading,
		refetch,
	} = useQuery<ItemUserImagesDto[]>(
		['images', itmId],
		() => itemService.getImagesUsers(itmId) as Promise<ItemUserImagesDto[]>,
		{},
	);

	if (isLoading) {
		return null;
	}
	/*
	function accept(row: ItemUserImagesDto) {
		void itemService.updImages(itmId, 'accept', row.usrId, row.image.id).then(async () => {
			onImagesUpd();
			await refetch();
		});
	}

	function hide(row: ItemUserImagesDto) {
		void itemService.updImages(itmId, 'hide', row.usrId, row.image.id).then(async () => {
			await refetch();
		});
	}

	function remove(row: ItemUserImagesDto) {
		void itemService.updImages(itmId, 'delete', row.usrId, row.image.id).then(async () => {
			await refetch();
		});
	}*/

	return (
		<div className='table-wrapper'>
			{images && images.length > 0 && (
				<Table size='small'>
					<TableHead>
						<TableRow>
							<TableCell>id</TableCell>
							<TableCell>Image</TableCell>
							<TableCell>Usr</TableCell>
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{images.map((row) => (
							<TableRow key={row.image.id}>
								<TableCell>{row.image.id}</TableCell>
								<TableCell>
									<img width={150} src={getListImg(row.image.path)} alt={''} />
								</TableCell>
								<TableCell>{row.usrId}</TableCell>
								<TableCell>
									{mainImages[0]?.id != row.image.id && (
										<ButtonDialog
											text='Set'
											onValid={() =>
												void itemService
													.updateMainImage(itmId, row.image?.id ?? 0)
													.then(() => {
														void refetch();
														void onImagesUpd();
													})
											}
											confirmBtnCancel='Annuler'
											confirmBtnValid='Appliquer'
											confirmTitle='Souhaitez vous faire de cette image la version principale ?'
											confirmDesc=''
										/>
									)}

									{!mainImages.find((i) => i.id == row.image.id) && (
										<>
											<Button
												variant='outlined'
												size='small'
												sx={{
													minWidth: '20px',
												}}
												onClick={() =>
													void api
														.call(
															'PUT',
															`/admin/item/${itmId}/imageSecondary/${row.image.id}/`,
															{},
														)
														.then(() => {
															void itemService
																.updImages(
																	itmId,
																	'hide',
																	row.usrId,
																	row.image.id,
																)
																.then(() => {
																	void refetch();
																	void onImagesUpd();
																});
														})
												}>
												<AddIcon />
											</Button>
											<Button
												onClick={() =>
													void itemService
														.updImages(
															itmId,
															'hide',
															row.usrId,
															row.image.id,
														)
														.then(() => {
															void refetch();
															void onImagesUpd();
														})
												}
												color={'error'}
												variant='outlined'
												size='small'
												sx={{
													minWidth: '20px',
												}}
												disabled={row.refused}>
												<CloseIcon />
											</Button>
										</>
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			)}
		</div>
	);
};
