import React from 'react';
import Wrapper from '../../../components/Wrapper';
import { useAuthor } from './use-authors';
import { api } from '../../../_services';
import ButtonDialog from '../../../components/Widgets/ButtonDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import AuthorSearchModal from './AuthorSearchModal';
import PageTitle from 'layout-components/PageTitle';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../components/Loader';
import { formatAuthorName } from './AuthorUtiliy';
import { useAuthorItemsByAuthor } from './use-authors-items';
import { AuthorItemEdit } from './AuthorItemEdit';
import FormControl from '@mui/material/FormControl';
import { TextField } from '@mui/material';

const AuthorPage = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const { data: author, isLoading, refetch: refetchAuthor } = useAuthor(Number(id) ?? 0);
	const { data: items, refetch: refetchItems } = useAuthorItemsByAuthor(Number(id) ?? 0);

	if (!author?.data || isLoading) {
		return <Loader />;
	}

	return (
		<>
			<PageTitle titleHeading={formatAuthorName(author.data)} titleDescription='' />

			<Wrapper sectionHeading='Detail'>
				<FormControl fullWidth>
					<TextField
						label='Name'
						type={'text'}
						onBlur={(event) =>
							void api.call(
								'PUT',
								`/admin/authors/${author?.data.id}/updName/`,
								event.target.value,
							)
						}
						defaultValue={author?.data.name}
					/>
				</FormControl>
				<FormControl fullWidth>
					<TextField
						label='Prénom'
						type={'text'}
						onBlur={(event) =>
							void api.call(
								'PUT',
								`/admin/authors/${author?.data.id}/updLastname/`,
								event.target.value,
							)
						}
						defaultValue={author?.data.lastname}
					/>
				</FormControl>
				<FormControl fullWidth>
					<TextField
						label='Surnom'
						type={'text'}
						onBlur={(event) =>
							void api.call(
								'PUT',
								`/admin/authors/${author?.data.id}/updSurname/`,
								event.target.value,
							)
						}
						defaultValue={author?.data.surname}
					/>
				</FormControl>
			</Wrapper>
			<Wrapper sectionHeading='Variantes'>
				<ul>
					{author?.data?.variants?.map((value) => (
						<li key={value}>
							{value}&nbsp;
							<ButtonDialog
								onValid={() => {
									void api
										.call(
											'DELETE',
											`/admin/authors/${author.data.id}/variante/`,
											{ variante: value },
										)
										.then(refetchAuthor);
								}}
								text='Supprimer'
								color='error'
								confirmBtnCancel='Annuler'
								confirmBtnValid='Supprimer'
								confirmTitle='Souhaitez vous supprimer cette variante ?'
								confirmDesc="L'action ne sera pas annulable!"
							/>
						</li>
					))}
				</ul>
			</Wrapper>

			<Wrapper sectionHeading='Items'>
				{items?.data && <AuthorItemEdit data={items.data} onChange={refetchItems} />}
			</Wrapper>

			<Wrapper>
				<AuthorSearchModal
					author={author.data}
					buttonTitle={'Fusionner'}
					onAdd={(authorId) => {
						{
							void api
								.call('PUT', `/admin/authors/${author.data.id}/fusion/${authorId}/`)
								.then(() =>
									navigate(`/book/authors/${authorId}`, { replace: true }),
								);
						}
					}}
				/>
				&nbsp;
				<ButtonDialog
					onValid={() => {
						void api
							.call('DELETE', `/admin/authors/${author.data.id}/`)
							.then(() => navigate('/book/authors'));
					}}
					text='Supprimer'
					color='error'
					startIcon={<DeleteIcon />}
					confirmBtnCancel='Annuler'
					confirmBtnValid='Supprimer'
					confirmTitle='Souhaitez vous supprimer cette auteur ?'
					confirmDesc="L'action ne sera pas annulable!"
				/>
			</Wrapper>
		</>
	);
};

export default AuthorPage;
