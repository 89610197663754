import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { api, searchService } from '_services';
import Alert from '@mui/material/Alert';
import { Duplications } from './Duplications';
import Wrapper from 'components/Wrapper';

const Images = () => {
	const [msg, setMsg] = useState<string>('');
	return (
		<>
			<Wrapper sectionHeading='Actions'>
				<Button
					variant='contained'
					disableElevation
					onClick={() => api.call('PUT', '/admin/images/duplications/rebuild/')}>
					Duplications check
				</Button>
				&nbsp;
				<Button
					variant='contained'
					disableElevation
					onClick={() =>
						searchService.cleanImg().then(() => {
							setMsg('clean des images en cours, async pas de retour');
						})
					}>
					Clean les images
				</Button>
				&nbsp;
				<Button
					variant='contained'
					disableElevation
					onClick={() =>
						searchService.resizeImg().then(() => {
							setMsg('resize des images en cours, async pas de retour');
						})
					}>
					Resize toutes les images
				</Button>
				{msg && <Alert severity='info'>{msg}</Alert>}
			</Wrapper>

			<Duplications />
		</>
	);
};

export default Images;
