/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useSearchParams } from 'react-router-dom';

export const useTypedSearchParams = <T extends Record<string, string | string[]>>(
	defaultInit?: Record<string, string | string[]> | undefined,
) => {
	const [searchParams, setSearchParams] = useSearchParams(defaultInit);

	const searchAsObject = {};
	for (const [key, value] of searchParams) {
		if (defaultInit && defaultInit[key] != undefined && defaultInit[key] instanceof Array) {
			// @ts-ignore
			if (searchAsObject[key] != undefined) {
				// @ts-ignore
				// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
				searchAsObject[key].push(value);
			} else {
				// @ts-ignore
				searchAsObject[key] = [value];
			}
		} else {
			// @ts-ignore
			searchAsObject[key] = value;
		}
	}

	return [
		searchAsObject as T,
		(nextInit: Record<string, string | string[]>) => {
			return setSearchParams({ ...searchAsObject, ...nextInit }, { replace: true });
		},
	] as const;
};
