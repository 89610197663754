import React from 'react';
import { searchService } from '_services';

import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

class Search extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			msg: '',
		};
	}
	reindex() {
		this.setState({ msg: 'Indexation en cours ...' });

		searchService.reindex().then((response) => {
			if (response.result) {
				this.setState({ msg: 'Processus fini' });
			} else {
				this.setState({ msg: 'Processus en erreur : ' + response.msg });
			}
		});
	}
	render() {
		const { msg } = this.state;
		return (
			<>
				<div>
					<Button variant='contained' disableElevation onClick={() => this.reindex()}>
						Réindexer le moteur de recherche
					</Button>
					<Alert severity='info'>{msg}</Alert>
				</div>
			</>
		);
	}
}

export default Search;
