import { api } from '_services';

export const searchService = {
	reindex() {
		return api.call('GET', '/admin/reindex/');
	},
	cleanImg() {
		return api.call('GET', '/admin/images/clean/');
	},
	resizeImg() {
		return api.call('GET', '/admin/images/resizeall/');
	},
};
