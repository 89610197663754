import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import React from 'react';

const containerStyle = {
	position: 'relative',
	width: '100%',
	height: '200px',
};
const BrewMap = (props) => {
	if (props.latitude && props.longitude) {
		return (
			<Map
				google={props.google}
				zoom={16}
				containerStyle={containerStyle}
				initialCenter={{
					lat: props.latitude,
					lng: props.longitude,
				}}
			>
				<Marker
					name={'Current location'}
					position={{ lat: props.latitude, lng: props.longitude }}
				/>
			</Map>
		);
	}
	return '';
};

export default GoogleApiWrapper({
	apiKey: 'AIzaSyCq5AL36HqDdBMubVoVeEjo_AKgO7b9s-8',
})(BrewMap);
