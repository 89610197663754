import { Component } from 'react';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import Routes from './Routes';
import './style.css';

import { QueryClient, QueryClientProvider } from 'react-query';

const store = configureStore();

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: 2,
			refetchOnWindowFocus: false,
		},
	},
});

class App extends Component {
	render() {
		return (
			<Provider store={store}>
				<QueryClientProvider client={queryClient}>
					<Routes />
				</QueryClientProvider>
			</Provider>
		);
	}
}

export default App;
