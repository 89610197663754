import { FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import ButtonPrimary from 'components/Widgets/ButtonPrimary';
import { useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { breweryResult, breweryService } from '_services';

const validationSchema = Yup.object({
	name: Yup.string().required('name is required'),
	website: Yup.string().nullable(),
	address1: Yup.string().nullable(),
	address2: Yup.string().nullable(),
	zipcode: Yup.string().nullable(),
	city: Yup.string().nullable(),
	country: Yup.string().nullable(),
	visitable: Yup.string(),
});
const EditForm = (props) => {
	const formik = useFormik({
		initialValues: {
			id: 0,
			name: props.data.name ? props.data.name : '',
			country: props.data.country ? props.data.country : '',
			city: props.data.city ? props.data.city : '',
			website: props.data.website ? props.data.website : '',
			address1: props.data.address1 ? props.data.address1 : '',
			address2: props.data.address2 ? props.data.address2 : '',
			zipcode: props.data.zipcode ? props.data.zipcode : '',
			visitable: props.data.visitable ? 'true' : 'false',
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			var sendData: breweryResult = {
				id: 0,
				name: values.name ? values.name : '',
				country: values.country,
				city: values.city,
				website: values.website,
				address1: values.address1,
				address2: values.address2,
				zipcode: values.zipcode,
				visitable: props.data.visitable == 'true' ? true : false,
			};

			if (props.data.id > 0) {
				breweryService.upd(props.data.id, sendData).then((res) => {
					props.change();
				});
			} else {
				breweryService.add(sendData).then((res) => {
					props.change();
				});
			}
		},
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<TextField
				fullWidth
				className='m-2'
				id='name'
				name='name'
				label='Titre'
				value={formik.values.name}
				onChange={formik.handleChange}
				error={formik.touched.name && Boolean(formik.errors.name)}
				helperText={formik.touched.name && formik.errors.name}
			/>

			<TextField
				fullWidth
				className='m-2'
				id='address1'
				name='address1'
				label='Adresse'
				value={formik.values.address1}
				onChange={formik.handleChange}
				error={formik.touched.address1 && Boolean(formik.errors.address1)}
				helperText={formik.touched.address1 && formik.errors.address1}
			/>
			<TextField
				fullWidth
				className='m-2'
				id='address2'
				name='address2'
				label='Complément'
				value={formik.values.address2}
				onChange={formik.handleChange}
				error={formik.touched.address2 && Boolean(formik.errors.address2)}
				helperText={formik.touched.address2 && formik.errors.address2}
			/>
			<TextField
				fullWidth
				className='m-2'
				id='zipcode'
				name='zipcode'
				label='Code postal'
				value={formik.values.zipcode}
				onChange={formik.handleChange}
				error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
				helperText={formik.touched.zipcode && formik.errors.zipcode}
			/>
			<TextField
				fullWidth
				className='m-2'
				id='city'
				name='city'
				label='Ville'
				value={formik.values.city}
				onChange={formik.handleChange}
				error={formik.touched.city && Boolean(formik.errors.city)}
				helperText={formik.touched.city && formik.errors.city}
			/>
			<TextField
				fullWidth
				className='m-2'
				id='country'
				name='country'
				label='Pays'
				value={formik.values.country}
				onChange={formik.handleChange}
				error={formik.touched.country && Boolean(formik.errors.country)}
				helperText={formik.touched.country && formik.errors.country}
			/>

			<TextField
				fullWidth
				className='m-2'
				id='website'
				name='website'
				label='Site web'
				value={formik.values.website}
				onChange={formik.handleChange}
				error={formik.touched.website && Boolean(formik.errors.website)}
				helperText={formik.touched.website && formik.errors.website}
			/>

			<RadioGroup
				aria-label='visitable'
				name='visitable'
				value={formik.values.visitable}
				onChange={formik.handleChange}
			>
				<FormControlLabel value='true' control={<Radio />} label='Oui' />
				<FormControlLabel value='false' control={<Radio />} label='Non' />
			</RadioGroup>

			<ButtonPrimary onClick={() => formik.handleSubmit()} text='Valider' />
		</form>
	);
};

export default EditForm;
