import Button from '@mui/material/Button';

import Wrapper from 'components/Wrapper';
import React, { useEffect, useState } from 'react';
import { breweryService } from '_services';
import { FilterItemsConsumerHook } from '_store/filters';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

const columns = [
	{ field: 'name', headerName: 'Titre', flex: 0.5 },
	{ field: 'city', headerName: 'Ville', flex: 0.1 },
	{ field: 'created', headerName: 'Date', flex: 0.1 },
];

const Breweries = (props) => {
	const navigate = useNavigate();
	const [filters, updFilters] = FilterItemsConsumerHook();
	const [fetchItem, setFetchItem] = useState({
		loaded: false,
		items: [],
		types: [],
	});
	const limit = 50;
	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: limit,
	});

	useEffect(() => {
		if (!fetchItem.loaded) {
			breweryService.find().then((resp) => {
				var tmpdata = { ...fetchItem, loaded: true };
				tmpdata.items = resp;
				setFetchItem(tmpdata);
			});
		}
	}, [fetchItem]);

	useEffect(() => {
		updFilters({
			type: 'page',
			value: paginationModel.page,
		});
	}, [paginationModel]);

	function goToDetail(id) {
		navigate('/brewery/detail/' + id);
	}
	return (
		<Wrapper>
			<DataGrid
				autoHeight
				rows={fetchItem.items}
				columns={columns}
				paginationModel={paginationModel}
				paginationMode='server'
				onPaginationModelChange={setPaginationModel}
				page={filters.page}
				onPageChange={(params) => {}}
				loading={!fetchItem.loaded}
				onCellClick={(row, column, event) => {
					goToDetail(row.row.id);
				}}
				sortModel={[
					{
						field: 'created',
						sort: 'desc',
					},
				]}
			/>

			<Button variant='contained' disableElevation size='small' onClick={() => goToDetail(0)}>
				Ajouter
			</Button>
		</Wrapper>
	);
};

export default Breweries;
