import React from 'react';
import { Button, ListItem, ListItemText, Typography } from '@mui/material';
import { SourceMedia } from '../../../types/global';
import { api } from '../../../_services';
import ButtonDialog from '../../../components/Widgets/ButtonDialog';
import DeleteIcon from '@mui/icons-material/Delete';

interface SourceListProps {
	itemId: number;
	source: SourceMedia;
	onClick: () => void;
	refetch?: () => void;
}

export default function SourceList({ itemId, source, onClick, refetch }: SourceListProps) {
	return (
		<ListItem onClick={onClick} sx={{ paddingX: 1 }}>
			<ListItemText>
				<Typography variant='caption' component='div'>
					{source.srcId} {source.isMain && '(Principal)'} :<br />
					<ul>
						<li>
							<a href={source.url} target={'_blank'} rel='noreferrer'>
								{source.url}
							</a>
						</li>
						{source.ean && <li>Ean: {source.ean}</li>}
						{source.lastRefresh && <li>lastRefresh: {source.lastRefresh}</li>}
						{source.editionsLastRefresh && (
							<li>editionsLastRefresh: {source.editionsLastRefresh}</li>
						)}

						<li>
							{source.srcId === 82 && (
								<>
									<Button
										size='small'
										variant='contained'
										disableElevation
										color='primary'
										onClick={() => {
											void api
												.call(
													'GET',
													`/admin/item/${itemId}/refetch/${source.id}/`,
												)
												.then(() => refetch?.());
										}}>
										Refetch Item
									</Button>
									&nbsp;
									<Button
										size='small'
										variant='contained'
										disableElevation
										color='primary'
										onClick={() => {
											void api
												.call(
													'GET',
													`/admin/item/${itemId}/refetch/${source.id}/editions/`,
												)
												.then(() => refetch?.());
										}}>
										Refetch Editions
									</Button>
								</>
							)}
							&nbsp;
							<ButtonDialog
								onValid={() =>
									void api
										.call(
											'DELETE',
											`/admin/item/${itemId}/refetch/${source.id}/`,
										)
										.then(() => refetch?.())
								}
								text='Supprimer'
								color='error'
								startIcon={<DeleteIcon />}
								confirmBtnCancel='Annuler'
								confirmBtnValid='Supprimer'
								confirmTitle='Souhaitez vous supprimer cette source ?'
								confirmDesc="L'action ne sera pas annulable!"
							/>
						</li>
					</ul>
				</Typography>
			</ListItemText>
		</ListItem>
	);
}
