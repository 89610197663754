import { createMuiTheme } from '@mui/material';
import typography from './typography';

const MuiTheme = createMuiTheme({
	shadows: ['none'],
	palette: {
		primary: {
			main: '#37253b',
		},
		grey: {
			300: '#fefefe',
			A100: '#f8f9ff',
		},
		secondary: {
			main: '#37253b',
		},
		contrastThreshold: 3,
		tonalOffset: 0.1,
	},
	shape: {
		borderRadius: '0.1rem',
	},
	overrides: {
		MuiButton: {
			disableElevation: true,
			text: {
				paddingLeft: '14px',
				paddingRight: '14px',
			},
			containedSizeSmall: {
				paddingLeft: '14px',
				paddingRight: '14px',
			},
			root: {
				textTransform: 'none',
				fontWeight: 'normal',
			},
		},
		MuiTable: {
			backgroundColor: 'white',
		},
		MuiGrid: {
			backgroundColor: 'white',
		},
		MuiTooltip: {
			tooltip: {
				backgroundColor: '#d0d2da',
				padding: '8px 16px',
				fontSize: '13px',
			},
			arrow: {
				color: '#d0d2da',
			},
		},
	},
	typography,
});

export default MuiTheme;
