import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Wrapper from 'components/Wrapper';
import React, { useEffect, useState } from 'react';
import { userService } from '_services';
import { UserAdminDto } from '_services/userDto';
import { useQuery } from 'react-query';

const UsersList = () => {
	const { data: users, isLoading } = useQuery({
		queryKey: ['users', 'list'],
		queryFn: () => userService.getAllForAdmin(),
	});

	if (isLoading) {
		return null;
	}

	return (
		<>
			<Wrapper>
				<TableContainer className='mb-4'>
					<Table aria-label='simple table'>
						<TableHead>
							<TableRow>
								<TableCell>id</TableCell>
								<TableCell>name</TableCell>
								<TableCell>Count</TableCell>
								<TableCell>Last co</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{users &&
								users.map((usr) => {
									return (
										<TableRow key={usr.id} hover>
											<TableCell>{usr.id}</TableCell>
											<TableCell>
												{usr.name}
												<br />
												{usr.email}
											</TableCell>
											<TableCell>{usr.count}</TableCell>
											<TableCell>
												{new Date(usr.lastLogin).toLocaleDateString()}
											</TableCell>
										</TableRow>
									);
								})}
						</TableBody>
					</Table>
				</TableContainer>
			</Wrapper>
		</>
	);
};

export default UsersList;
