import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Wrapper from 'components/Wrapper';
import React, { useEffect, useState } from 'react';
import { itemService } from '_services';
import { AdminLastItem } from '_services/itemUser.result';
import { useNavigate } from 'react-router-dom';

interface LastaddData {
	loaded: boolean;
	itemUser: Array<AdminLastItem>;
}
const Lastadd = () => {
	const navigate = useNavigate();
	const [data, setData] = useState({
		loaded: false,
		itemUser: [],
	} as LastaddData);

	useEffect(() => {
		if (!data.loaded) {
			void itemService.getLastitemAdd().then((resp) => {
				setData({ ...data, loaded: true, itemUser: resp });
			});
		}
	}, [data]);

	function goToDetail(id: number) {
		navigate(`/items/detail/${id}`);
	}

	return (
		<>
			<Wrapper>
				<TableContainer className='mb-4'>
					<Table aria-label='simple table'>
						<TableHead>
							<TableRow>
								<TableCell>User</TableCell>
								<TableCell>Item</TableCell>
								<TableCell>Type</TableCell>
								<TableCell>Date</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.itemUser.map((itmUser) => {
								return (
									<TableRow
										key={itmUser.usrId + itmUser.itmId}
										hover
										onClick={() => goToDetail(itmUser.itmId)}>
										<TableCell>{itmUser.usrName}</TableCell>
										<TableCell>{itmUser.title}</TableCell>
										<TableCell>{itmUser.typeId}</TableCell>
										<TableCell>
											{new Date(itmUser.created).toLocaleDateString()}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Wrapper>
		</>
	);
};

export default Lastadd;
