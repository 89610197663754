import { useSeriesGroup, useSeriesGroupSearch } from './use-series-group';
import Wrapper from '../../../components/Wrapper';
import QueryNextPage from '../../../components/QueryNextPage';
import PageTitle from 'layout-components/PageTitle';
import React from 'react';
import SerieGroupList from './SerieGroupList';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Table, TableBody } from '@mui/material';
import SerieGroupCreate from './SerieGroupCreate';
import { FormProvider, useForm } from 'react-hook-form';
import { useSyncFormWithUrl } from '../../../utils/useSyncFormWithUrl';
import { useTypedSearchParams } from '../../../utils/useTypedSearchParams';

const useRequest = () => {
	const [searchParam] = useTypedSearchParams<SerieGroupPageParams>();

	const requestSearch = useSeriesGroupSearch(searchParam.search ?? '');
	const requestNormal = useSeriesGroup();

	if (searchParam.search && searchParam.search.length > 2) {
		return requestSearch;
	}

	return requestNormal;
};
type SerieGroupPageParams = {
	search?: string;
};
type FiltersParams = {
	search: string;
};
const SeriesGroupPage = () => {
	const {
		data: serieGroups,
		hasNextPage,
		isFetchingNextPage,
		fetchNextPage,
		refetch,
	} = useRequest();

	const methods = useForm<FiltersParams>({
		mode: 'onChange',
		defaultValues: {},
	});

	useSyncFormWithUrl(methods.watch);
	return (
		<>
			<PageTitle titleHeading='Groupement de séries' titleDescription='' />

			<Wrapper>
				<FormProvider {...methods}>
					<div>
						<label>
							Recherche
							<input
								type='text'
								className='form-control'
								{...methods.register('search')}
							/>
						</label>
					</div>
				</FormProvider>
			</Wrapper>
			<Wrapper>
				<Table size='small' className='table-responsive'>
					<TableHead>
						<TableRow>
							<TableCell>Titre</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{serieGroups?.pages.map((serieGroup) =>
							serieGroup.data.objects.map((value) => (
								<SerieGroupList key={value.id} serieGroup={value} />
							)),
						)}
					</TableBody>
				</Table>
				<QueryNextPage
					hasNextPage={hasNextPage}
					isFetchingNextPage={isFetchingNextPage}
					fetchNextPage={fetchNextPage}
				/>
			</Wrapper>

			<SerieGroupCreate onNew={() => refetch()} />
		</>
	);
};

export default SeriesGroupPage;
