import { authenticationService } from '_services';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const Logout = () => {
	const navigate = useNavigate();
	useEffect(() => {
		authenticationService.logout();
		navigate('/login');
	}, []);
	return null;
};
export default Logout;
