import Wrapper from 'components/Wrapper';
import PageTitle from '../../layout-components/PageTitle';
import EditForm from 'pages/notify/EditForm';
import React from 'react';

const Notify = () => {
	return (
		<>
			<PageTitle titleHeading='Notification' titleDescription='' />
			<Wrapper>
				<EditForm title='' desc='' />
			</Wrapper>
			<Wrapper>
				<EditForm
					title='Vous buvez une bière ?'
					desc='Pensez à la scanner pour ne pas oublier ce breuvage ! '
				/>
			</Wrapper>
		</>
	);
};

export default Notify;
