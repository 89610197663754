import { useEffect } from 'react';
import { UseFormWatch, WatchObserver } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types';
import { useSearchParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

/**
 * Génération d'un URLSearchParams à partir de données de formulaire
 *
 * @param data Donnée d'un formulaire react-hook-form
 * @returns Un ensemble de données utilisable dans un search
 */
export const generateQueryParamsFromData = <TFieldValues extends FieldValues>(
	params: TFieldValues,
) => {
	const searchParams = new URLSearchParams();
	Object.entries(params).forEach(([key, values]) => {
		if (values) {
			if (Array.isArray(values)) {
				values.forEach((value) => {
					searchParams.append(key, value as string);
				});
			} else {
				searchParams.append(key, values as string);
			}
		}
	});
	return searchParams;
};

/**

 * Synchronisation de données de formulaire react-hook-form avec les query params
 */
export const useSyncFormWithUrl = <TFieldValues extends FieldValues>(
	watch: UseFormWatch<TFieldValues>,
) => {
	const [, setSearchParams] = useSearchParams();

	const callback: WatchObserver<TFieldValues> = (currentFormValues) =>
		setSearchParams(generateQueryParamsFromData(currentFormValues), { replace: true });

	/**
	 * On doit debounce les appels à History pour éviter des appels trop fréquents lorsqu'on tape du texte.
	 * Sans ça, on risque de provoquer l'erreur "too many calls to location or history apis within a short timeframe"
	 */
	const onWatchcallback = useDebouncedCallback(callback, 150, { leading: true, trailing: true });

	useEffect(() => {
		const { unsubscribe } = watch(onWatchcallback);

		return unsubscribe;
	}, [onWatchcallback, watch]);
};
