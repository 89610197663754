import { useSearchSerieInfinite, useSeries } from './use-series';
import Wrapper from '../../../components/Wrapper';
import SerieList from './SerieList';
import QueryNextPage from '../../../components/QueryNextPage';
import PageTitle from 'layout-components/PageTitle';
import { FormProvider, useForm } from 'react-hook-form';
import { useSyncFormWithUrl } from '../../../utils/useSyncFormWithUrl';
import { useTypedSearchParams } from '../../../utils/useTypedSearchParams';
import { useNavigate } from 'react-router-dom';
import React from 'react';

const useRequest = (params: FiltersParams) => {
	const requestSearch = useSearchSerieInfinite(params.search);
	const requestNormal = useSeries({
		override: Boolean(params.overrides),
	});

	if (params.search && params.search.length > 2) {
		return requestSearch;
	}

	return requestNormal;
};

type FiltersParams = {
	overrides: string;
	search: string;
};
const SeriesPage = () => {
	const navigate = useNavigate();
	const [searchParam] = useTypedSearchParams<FiltersParams>();

	const {
		data: series,
		hasNextPage,
		isFetchingNextPage,
		fetchNextPage,
	} = useRequest(searchParam);

	const methods = useForm<FiltersParams>({
		mode: 'onChange',
		defaultValues: { ...searchParam },
	});

	useSyncFormWithUrl(methods.watch);

	return (
		<>
			<PageTitle titleHeading='Séries' titleDescription='' />

			<Wrapper>
				<FormProvider {...methods}>
					<div>
						<label>
							<input type='checkbox' {...methods.register('overrides')} /> &nbsp;Voir
							que les surcharges
						</label>
					</div>
					OU
					<div>
						<label>
							Recherche
							<input
								type='text'
								className='form-control'
								{...methods.register('search')}
							/>
						</label>
					</div>
				</FormProvider>
			</Wrapper>

			<Wrapper>
				{series?.pages?.map(
					(serie) =>
						serie?.data?.objects.map((value) => (
							<SerieList
								key={value.id}
								serie={value}
								onClick={() => navigate(`/book/series/${value.id}`)}
							/>
						)),
				)}
				<QueryNextPage
					hasNextPage={hasNextPage}
					isFetchingNextPage={isFetchingNextPage}
					fetchNextPage={fetchNextPage}
				/>
			</Wrapper>
		</>
	);
};

export default SeriesPage;
