import React, { useState } from 'react';
import { useSearchSerie } from '../series/use-series';
import Wrapper from '../../../components/Wrapper';
import Loader from '../../../components/Loader';
import { SeriesDto, SeriesGroupDto } from '../../../types/global';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { api, authenticationService } from '../../../_services';
import SerieList from '../series/SerieList';
import { FormProvider, useForm } from 'react-hook-form';
import OutlinedInput from '@mui/material/OutlinedInput';

interface SerieGroupCreateProps {
	onNew?: () => void;
}

type CreateParam = {
	name: string;
};

const SerieGroupCreate = ({ onNew }: SerieGroupCreateProps) => {
	const methods = useForm<CreateParam>({
		mode: 'onChange',
	});

	const onSubmit = ({ name }: CreateParam) => {
		void api
			.call('POST', '/admin/series/group/', {
				name,
			})
			.then(() => {
				methods.resetField('name');
				if (onNew) {
					onNew();
				}
			});
	};

	return (
		<Wrapper sectionHeading='Ajouter un groupement'>
			<FormProvider {...methods}>
				<div className='form-group'>
					<OutlinedInput
						fullWidth
						type='text'
						className='form-control'
						{...methods.register('name')}
					/>
				</div>

				<div className='form-group'>
					<Button
						size='small'
						onClick={() => {
							onSubmit(methods.getValues());
						}}
						variant='contained'
						disableElevation
						color='primary'>
						Creer
					</Button>
				</div>
			</FormProvider>
		</Wrapper>
	);
};

export default SerieGroupCreate;
