import React from 'react';

import { getListImg } from '_helpers';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ButtonDialog from '../../components/Widgets/ButtonDialog';
import { useItem } from './use-item';
import { api, itemService } from '../../_services';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';

type DetailImagesProps = {
	itmId: number;
	onImagesUpd: () => void;
};

export const DetailImages = ({ itmId, onImagesUpd }: DetailImagesProps) => {
	const { data: item, isLoading, refetch } = useItem(Number(itmId));

	if (isLoading) {
		return null;
	}

	const images = item?.data?.secondaryImages ?? [];

	return (
		<div className='table-wrapper'>
			{images.length > 0 && (
				<Table size='small'>
					<TableHead>
						<TableRow>
							<TableCell>Id</TableCell>
							<TableCell>Image</TableCell>
							<TableCell>Pos</TableCell>
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{images.map((row, idx) => (
							<TableRow key={row.id}>
								<TableCell>{row.id}</TableCell>
								<TableCell>
									<img width={150} src={getListImg(row.path)} alt={''} />
								</TableCell>
								<TableCell>
									<FormControl>
										<OutlinedInput
											label='Pos'
											type={'number'}
											sx={{
												width: '75px',
											}}
											onChange={async (event) => {
												await api
													.call(
														'PUT',
														`/admin/item//${itmId}/imageSecondary/${row.id}/pos/${event.target.value}/`,
														{},
													)
													.then(() => {
														void refetch();
														void onImagesUpd();
													});
												event.preventDefault();
											}}
											defaultValue={idx + 1}
										/>
									</FormControl>
								</TableCell>
								<TableCell>
									<ButtonDialog
										text='Supprimer'
										color='error'
										onValid={() =>
											void api
												.call(
													'DELETE',
													`/admin/item/${itmId}/imageSecondary/${row.id}/`,
													{},
												)
												.then(() => {
													void refetch();
													void onImagesUpd();
												})
										}
										confirmBtnCancel='Annuler'
										confirmBtnValid='Supprimer'
										confirmTitle='Souhaitez vous supprimer cette image secondaire ?'
										confirmDesc={''}
									/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			)}
		</div>
	);
};
