import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import Divider from '@mui/material/Divider';

function PageTitle(props) {
	return (
		<>
			<Box sx={{ padding: 1 }}>
				<Typography variant={'h1'}>{props.titleHeading}</Typography>
				<Typography variant={'caption'}>{props.titleDescription}</Typography>
			</Box>
			<Divider />
		</>
	);
}

export default PageTitle;
