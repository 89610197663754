import ButtonPrimary from "components/Widgets/ButtonPrimary";
import { Form, Formik } from "formik";
import React from "react";
import * as yup from 'yup';
import { getListImg } from "_helpers";
import { breweryService } from '_services';


const BrewImage = (props) => {

	return (<>
		<Formik
			initialValues={{ file: null }}
			onSubmit={(values) => {

				breweryService.uploadImg(props.id, values.file).then(resp => {
					props.onChange();
				});

			}}
			validationSchema={yup.object().shape({
			})}
			render={({ values, handleSubmit, setFieldValue }) => {
				return (
					<>
						{props.image && <img src={getListImg(props.image.path)} />}
						<Form >
							<input id="file" name="file" type="file" className="form-control"
								onChange={event => {
									if (event && event.currentTarget && event.currentTarget.files) {
										setFieldValue("file", event.currentTarget.files[0]);
									}
								}} />


							<ButtonPrimary
								onClick={() => handleSubmit()}
								text="Upload"
							/>
						</Form>
					</>
				);
			}} />
	</>);
}

export default BrewImage;

