import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { convertToQueryString, Reponse } from '../../utils/request';
import { ImagesDto } from '../../types/global';
import { api } from '../../_services';
import { DuplicationForm } from './Duplications';

export interface ImagesDuplicationsResponse {
	imageSrc: ImagesDto;
	imagesDst: DuplicationResponse[];
}

export interface DuplicationResponse {
	id: number;
	refused: boolean;
	hashPerceptualScore: string;
	image: ImagesDto;
}

export const useDuplicationAll = (
	params: DuplicationForm,
	options: UseQueryOptions<Reponse<ImagesDuplicationsResponse[]>> = {},
): UseQueryResult<Reponse<ImagesDuplicationsResponse[]>> => {
	return useQuery<Reponse<ImagesDuplicationsResponse[]>>(
		['duplication', 'all', convertToQueryString(params)],
		() =>
			api.call(
				'GET',
				'/admin/images/duplications/?' + convertToQueryString(params),
			) as Promise<Reponse<ImagesDuplicationsResponse[]>>,
		{
			...options,
			enabled: parseFloat(params.scorePhashMin) >= 0 && parseFloat(params.scorePhashMax) >= 0,
		},
	);
};
