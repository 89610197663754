export function authHeader(file) {
  const token = localStorage.getItem('token');
  if (token) {
    if (file) {
      return {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      };
    } else {
      return {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      };
    }
  } else {
    return { 'Content-Type': 'application/json' };
  }
}
