import React from 'react';
import ImageList from './ImageList';
import { ListItem, ListItemText, Typography } from '@mui/material';
import { SeriesDto } from '../../../types/global';

interface SerieListProps {
	serie: SeriesDto;
	onClick: () => void;
}
export default function SerieList({ serie, onClick }: SerieListProps) {
	return (
		<ListItem onClick={onClick} sx={{ paddingX: 1 }}>
			<ImageList height={120} width={90} image={serie.image} />
			<ListItemText>
				<Typography variant='caption' component='div'>
					{serie.name}
				</Typography>
				<Typography variant='subtitle2' component='div'>
					{serie.userId > 0 ? `User=${serie.userId} - ` : ''}
					{serie.count} albums
				</Typography>
			</ListItemText>
		</ListItem>
	);
}
