export interface Reponse<T> {
	result: boolean;
	message?: string;
	exception?: string;
	data: T;
}

export const TIMEOUT_DEFAULT = 10000;
export const TIMEOUT_EXTEND = 30000;

class AbortError extends Error {
	constructor(message: string) {
		super();
		this.message = message;
		this.name = 'AbortError';
	}
}

const callTimeout = async <T>(
	url: string,
	{ method, body, headers = {} }: RequestInit = {},
	ms: number = TIMEOUT_DEFAULT,
): Promise<T> => {
	const controller = new AbortController();

	const timeout = setTimeout(() => controller.abort(), ms);

	return fetch(`/api${url}`, {
		method,
		body,
		headers: new Headers({
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.getItem('token') ?? ''}`,
			...headers,
		}),
		signal: controller.signal,
	})
		.then((response) => {
			if (response.status == 401) {
				console.log('dispatchEvent unauthorize');
				window.dispatchEvent(new Event('unauthorize'));
			}

			if (response.status != 200) {
				return Promise.reject(`Erreur ${response.status}`);
			}

			return response.json();
		})
		.catch((reason) => {
			if (reason instanceof Error) {
				if (reason.name == 'AbortError') {
					throw new AbortError("Le délais d'attente est trop long, veuillez réssayer.");
				}
			}
			throw reason;
		})
		.finally(() => clearTimeout(timeout)) as Promise<T>;
};

export const getRequest = <T>(
	url: string,
	fetchParams: RequestInit = {},
	timeout = TIMEOUT_DEFAULT,
): Promise<Reponse<T>> => callTimeout<Reponse<T>>(url, { method: 'GET', ...fetchParams }, timeout);

export const postRequest = <T>(
	url: string,
	fetchParams: RequestInit,
	timeout = TIMEOUT_DEFAULT,
): Promise<Reponse<T>> => {
	return callTimeout<Reponse<T>>(url, { method: 'POST', ...fetchParams }, timeout);
};

export const putRequest = <T>(
	url: string,
	fetchParams: RequestInit,
	timeout = TIMEOUT_DEFAULT,
): Promise<Reponse<T>> => {
	return callTimeout<Reponse<T>>(url, { method: 'PUT', ...fetchParams }, timeout);
};

export const deleteRequest = <T>(
	url: string,
	fetchParams: RequestInit = {},
	timeout = TIMEOUT_DEFAULT,
): Promise<Reponse<T>> => {
	return callTimeout<Reponse<T>>(url, { method: 'DELETE', ...fetchParams }, timeout);
};

export type QueryObjetType = Record<string, string | number | boolean | undefined>;
export const convertToQueryString = (obj?: QueryObjetType) => {
	if (!obj) return '';

	const str: string[] = [];
	for (const p in obj)
		if (obj.hasOwnProperty(p) && obj[p]) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p].toString())}`);
		}
	return str.join('&');
};
