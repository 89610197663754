import React from 'react';
import { SeriesGroupDto } from '../../../types/global';
import { Link } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import SerieGroupCreate from './SerieGroupCreate';

interface SerieListProps {
	serieGroup: SeriesGroupDto;
}
export default function SerieGroupList({ serieGroup }: SerieListProps) {
	return (
		<TableRow>
			<TableCell>
				<Link to={`/book/series/group/${serieGroup.id}`}>{serieGroup.name}</Link>
			</TableCell>
		</TableRow>
	);
}
