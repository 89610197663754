import React, { useState } from 'react';
import { useSearchSerie } from '../series/use-series';
import Wrapper from '../../../components/Wrapper';
import Loader from '../../../components/Loader';
import { SeriesDto, SeriesGroupDto } from '../../../types/global';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { api } from '../../../_services';
import SerieList from '../series/SerieList';
import { OutlinedInput } from '@mui/material';

interface SerieGroupAddProps {
	serieGroup: SeriesGroupDto;
	onChange?: () => void;
}

const SerieGroupAdd = ({ serieGroup, onChange }: SerieGroupAddProps) => {
	const [openModalSearchSerie, setOpenModalSearchSerie] = useState<SeriesDto>();
	const [text, setText] = useState('');

	const { data: serieSearch, isLoading } = useSearchSerie(text);

	return (
		<Wrapper sectionHeading='Ajouter une série'>
			<OutlinedInput
				fullWidth
				type='text'
				className='form-control'
				onChange={(t) => {
					setText(t.target.value);
				}}
			/>
			{isLoading && <Loader />}
			{serieSearch?.data?.objects
				.filter((s) => !serieGroup.series.map((sg) => sg.id).includes(s.id))
				.map((s) => (
					<SerieList key={s.id} serie={s} onClick={() => setOpenModalSearchSerie(s)} />
				))}

			<Dialog
				open={!!openModalSearchSerie}
				onClose={() => setOpenModalSearchSerie(undefined)}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'>
				<DialogTitle id='alert-dialog-title'>Ajouter la série ?</DialogTitle>
				<DialogActions>
					<Button onClick={() => setOpenModalSearchSerie(undefined)} color='primary'>
						Annuler
					</Button>
					<Button
						onClick={() =>
							api
								.call('PUT', `/admin/series/group/${serieGroup.id}/serie/`, {
									serieId: openModalSearchSerie?.id,
								})
								.then(() => {
									if (onChange) {
										onChange();
									}
									setOpenModalSearchSerie(undefined);
								})
						}
						color='primary'>
						Ajouter
					</Button>
				</DialogActions>
			</Dialog>
		</Wrapper>
	);
};

export default SerieGroupAdd;
