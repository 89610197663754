import {
	QueryFunctionContext,
	QueryKey,
	useInfiniteQuery,
	UseInfiniteQueryOptions,
	UseInfiniteQueryResult,
	UseMutateFunction,
	useMutation,
	UseMutationResult,
	useQuery,
	UseQueryOptions,
	UseQueryResult,
} from 'react-query';
import { Reponse } from '../../../utils/request';
import { api } from '../../../_services';
import {
	AuthorMedia,
	SearchResponse,
	SerieItemAdminDto,
	SeriesDto,
	SerieVariant,
	ValidationState,
} from '../../../types/global';

export interface Author {
	id: number;
	name: string;
	lastname: string;
	surname: string;
	created: string;
	variants: string[];
}

export interface UseAuthorsProps {
	override: boolean;
	sort: string;
	page: number;
	limit: number;
}
export const useAuthors = (
	props: UseAuthorsProps,
	options: UseQueryOptions<Reponse<SearchResponse<Author>>> = {},
): UseQueryResult<Reponse<SearchResponse<Author>>> => {
	return useQuery<Reponse<SearchResponse<Author>>>(
		['authors', props],
		() =>
			api.call(
				'GET',
				`/admin/authors/?limit=${props.limit}&page=${props.page}&sort=${
					props.sort
				}&override=${props.override ? '1' : '0'}`,
			) as Promise<Reponse<SearchResponse<Author>>>,
		{
			...options,
		},
	);
};

export const useAuthor = (
	id: number,
	options: UseQueryOptions<Reponse<Author>> = {},
): UseQueryResult<Reponse<Author>> => {
	return useQuery<Reponse<Author>>(
		['authors', id],
		() => api.call('GET', `/admin/authors/${id}/`) as Promise<Reponse<Author>>,
		{
			...options,
			enabled: id > 0,
		},
	);
};
