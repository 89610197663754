import React from 'react';
import Wrapper from '../../../components/Wrapper';
import { api } from '../../../_services';
import PageTitle from 'layout-components/PageTitle';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../components/Loader';
import FormControl from '@mui/material/FormControl';
import { TextField } from '@mui/material';
import EditorSearchModal from './EditorSearchModal';
import { useEditor } from './use-editors';

const EditorPage = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const { data: editor, isLoading } = useEditor(Number(id) ?? 0);

	if (!editor?.data || isLoading) {
		return <Loader />;
	}

	return (
		<>
			<PageTitle titleHeading={editor.data.name} titleDescription='' />

			<Wrapper sectionHeading='Detail'>
				<FormControl fullWidth>
					<TextField
						label='Name'
						type={'text'}
						onBlur={(event) =>
							void api.call(
								'PUT',
								`/admin/editors/${editor?.data.id}/name/`,
								event.target.value,
							)
						}
						defaultValue={editor?.data.name}
					/>
					<TextField
						label='Lien'
						type={'text'}
						onBlur={(event) =>
							void api.call(
								'PUT',
								`/admin/editors/${editor?.data.id}/link/`,
								event.target.value,
							)
						}
						defaultValue={editor?.data.link}
					/>
				</FormControl>
			</Wrapper>
			<Wrapper sectionHeading='Variantes'>
				<ul>{editor?.data?.variants?.map((value) => <li key={value}>{value}</li>)}</ul>
			</Wrapper>
			<Wrapper>
				<EditorSearchModal
					editor={editor.data}
					buttonTitle={'Fusionner'}
					onAdd={(authorRoleId) => {
						{
							void api
								.call(
									'PUT',
									`/admin/editors/${editor.data.id}/fusion/${authorRoleId}/`,
								)
								.then(() =>
									navigate(`/book/editors/${authorRoleId}`, { replace: true }),
								);
						}
					}}
				/>
			</Wrapper>
		</>
	);
};

export default EditorPage;
