import React, { useEffect, useState } from 'react';
import { OutlinedInput } from '@mui/material';
import { OutlinedInputProps } from '@mui/material/OutlinedInput/OutlinedInput';

const InputOnChange = (props: OutlinedInputProps & { onValueChange: (value: string) => void }) => {
	const [value, setValue] = useState<String>();

	useEffect(() => {
		let timeOutId: ReturnType<typeof setTimeout> | undefined = undefined;
		if (value && value.length > 2) {
			timeOutId = setTimeout(() => {
				if (props.onValueChange) {
					props.onValueChange((value as string) ?? '');
				}
			}, 500);
		}
		return () => {
			if (timeOutId) clearTimeout(timeOutId);
		};
	}, [value]);

	return <OutlinedInput {...props} onChange={(event) => setValue(event.target.value)} />;
};

export default InputOnChange;
