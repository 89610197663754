import React from 'react';
import Wrapper from '../../../components/Wrapper';
import PageTitle from 'layout-components/PageTitle';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { useEditors, useEditorsSearch } from './use-editors';
import QueryNextPage from '../../../components/QueryNextPage';
import EditorList from './EditorList';
import { useTypedSearchParams } from '../../../utils/useTypedSearchParams';
import { FormProvider, useForm } from 'react-hook-form';
import { useSyncFormWithUrl } from '../../../utils/useSyncFormWithUrl';

const useRequest = () => {
	const [searchParam] = useTypedSearchParams<EditorsPageParams>();

	const requestSearch = useEditorsSearch(searchParam.search ?? '');
	const requestNormal = useEditors({
		sort: searchParam.sort ?? 'name-asc',
	});

	if (searchParam.search && searchParam.search.length > 2) {
		return requestSearch;
	}

	return requestNormal;
};
type EditorsPageParams = {
	sort?: string;
	search?: string;
};

type FiltersParams = {
	sort: string;
	search: string;
};
const EditorsPage = () => {
	const [searchParam] = useTypedSearchParams<EditorsPageParams>();
	const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = useRequest();

	const methods = useForm<FiltersParams>({
		mode: 'onChange',
		defaultValues: {
			sort: searchParam.sort ?? 'name-asc',
		},
	});

	useSyncFormWithUrl(methods.watch);

	return (
		<>
			<PageTitle titleHeading='Editeurs' titleDescription='' />

			<Wrapper>
				<FormProvider {...methods}>
					<div>
						<select {...methods.register('sort')}>
							<option value={'name-asc'}>Nom croissant</option>
							<option value={'name-desc'}>Nom décroissant</option>
							<option value={'created-asc'}>Création croissant</option>
							<option value={'created-desc'}>Création décroissant</option>
						</select>
					</div>
					OU
					<div>
						<label>
							Recherche
							<input
								type='text'
								className='form-control'
								{...methods.register('search')}
							/>
						</label>
					</div>
				</FormProvider>
			</Wrapper>

			<Wrapper>
				<Table size='small' className='table-responsive'>
					<TableHead>
						<TableRow>
							<TableCell>Titre</TableCell>
							<TableCell>Date</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data?.pages.map((editor) =>
							editor.data.objects.map((value) => (
								<EditorList key={value.id} editor={value} />
							)),
						)}
					</TableBody>
				</Table>
				<QueryNextPage
					hasNextPage={hasNextPage}
					isFetchingNextPage={isFetchingNextPage}
					fetchNextPage={fetchNextPage}
				/>
			</Wrapper>
		</>
	);
};

export default EditorsPage;
