import {
	QueryFunctionContext,
	QueryKey,
	useInfiniteQuery,
	UseInfiniteQueryOptions,
	UseInfiniteQueryResult,
	useQuery,
	UseQueryOptions,
	UseQueryResult,
} from 'react-query';
import { Reponse } from '../../../utils/request';
import { api } from '../../../_services';
import { SearchResponse, SeriesGroupDto } from '../../../types/global';

export const useSerieGroup = (
	id: number,
	options: UseQueryOptions<Reponse<SeriesGroupDto>> = {},
): UseQueryResult<Reponse<SeriesGroupDto>> => {
	return useQuery<Reponse<SeriesGroupDto>>(
		['seriesgroup', id],
		() => api.call('GET', `/admin/series/group/${id}/`) as Promise<Reponse<SeriesGroupDto>>,
		{
			...options,
			enabled: id > 0,
		},
	);
};

export const useSeriesGroup = (
	options: UseInfiniteQueryOptions<Reponse<SearchResponse<SeriesGroupDto>>> = {},
): UseInfiniteQueryResult<Reponse<SearchResponse<SeriesGroupDto>>> => {
	return useInfiniteQuery<Reponse<SearchResponse<SeriesGroupDto>>>(
		['seriesgroup'],
		({ pageParam = 0 }: QueryFunctionContext<QueryKey, number>) =>
			api.call('GET', `/admin/series/group/?page=${pageParam / 30}`) as Promise<
				Reponse<SearchResponse<SeriesGroupDto>>
			>,
		{
			...options,
			getNextPageParam: (lastPage) => {
				return lastPage.data.nextOffset;
			},
		},
	);
};

export const useSeriesGroupSearch = (
	text: string,
	options: UseInfiniteQueryOptions<Reponse<SearchResponse<SeriesGroupDto>>> = {},
): UseInfiniteQueryResult<Reponse<SearchResponse<SeriesGroupDto>>> => {
	return useInfiniteQuery<Reponse<SearchResponse<SeriesGroupDto>>>(
		['SeriesGroupDto', 'search', text],
		() =>
			api.call('GET', `/series/group/search/?keyword=${text}`) as Promise<
				Reponse<SearchResponse<SeriesGroupDto>>
			>,
		{
			enabled: text !== undefined && text.length > 2,

			...options,
			getNextPageParam: () => {
				return undefined;
			},
		},
	);
};
