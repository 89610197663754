import React, { Context, createContext, useContext, useReducer } from 'react';

const initialState = {
	type: 0,
	brewery: 0,
	page: 0,
	overrides: "",
	search:"",
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'overrides':
			return {
				...state,
				overrides: action.value,
				page: 0
			};
		case 'type':
			return {
				...state,
				type: action.value,
				page: 0
			};
		case 'search':
			return {
				...state,
				search: action.value,
				page: 0
			};
		case 'page':
			return {
				...state,
				page: action.value
			};
		case 'brewery':
			return {
				...state,
				brewery: action.value,
				page: 0,
				type: 0,
				overrides: "",
				search:"",
			};
		default:
			return state;
	}
};

const FilterItems: Context<any> = createContext(initialState);

export const FilterItemsConsumer = FilterItems.Consumer;
export const FilterItemsConsumerHook = () => useContext(FilterItems);

export const FilterItemsProvider = ({ children }) => (
	<FilterItems.Provider value={useReducer(reducer, initialState)}>
		{children}
	</FilterItems.Provider>
);