import React, { useEffect, useState } from 'react';
import { useSearchSerie } from '../series/use-series';
import Wrapper from '../../../components/Wrapper';
import Loader from '../../../components/Loader';
import { SeriesDto, SeriesGroupDto } from '../../../types/global';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { api, authenticationService } from '../../../_services';
import SerieList from '../series/SerieList';
import { FormProvider, useForm } from 'react-hook-form';
import OutlinedInput from '@mui/material/OutlinedInput';

interface SerieGroupUpdProps {
	serie: SeriesDto;
	onUpd?: () => void;
}

type UpdateParam = {
	name: string;
};

const SerieUpd = ({ serie, onUpd }: SerieGroupUpdProps) => {
	const methods = useForm<UpdateParam>({
		mode: 'onChange',
		defaultValues: {
			name: serie.name,
		},
	});

	// aprè_s le refrech il actualise pas le formulaire sinon..
	useEffect(() => {
		methods.setValue('name', serie.name);
	}, [methods, serie.name]);

	const onSubmit = ({ name }: UpdateParam) => {
		void api
			.call('PUT', `/admin/series/${serie.id}/name`, {
				name,
			})
			.then(() => {
				methods.resetField('name');
				if (onUpd) {
					onUpd();
				}
			});
	};

	return (
		<Wrapper sectionHeading='Modifier la série'>
			<FormProvider {...methods}>
				<div className='form-group'>
					<OutlinedInput
						fullWidth
						type='text'
						className='form-control'
						{...methods.register('name')}
					/>
				</div>

				<div className='form-group'>
					<Button
						size='small'
						onClick={() => {
							onSubmit(methods.getValues());
						}}
						variant='contained'
						disableElevation
						color='primary'>
						Enregistrer
					</Button>
				</div>
			</FormProvider>
		</Wrapper>
	);
};

export default SerieUpd;
