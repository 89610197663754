import {
	QueryFunctionContext,
	QueryKey,
	useInfiniteQuery,
	UseInfiniteQueryOptions,
	UseInfiniteQueryResult,
	useQuery,
	UseQueryOptions,
	UseQueryResult,
} from 'react-query';
import { Reponse } from '../../../utils/request';
import { api } from '../../../_services';
import { SearchResponse } from '../../../types/global';
import { Author } from '../authors/use-authors';

export interface AuthorRoles {
	id: number;
	name: string;
	created: string;
	variants: string[];
}

export interface AuthorsRolesProps {
	sort: 'name-asc' | 'name-desc' | 'created-asc' | 'created-desc' | string;
}

export const useAuthorsRoles = (
	props: AuthorsRolesProps,
	options: UseInfiniteQueryOptions<Reponse<SearchResponse<AuthorRoles>>> = {},
): UseInfiniteQueryResult<Reponse<SearchResponse<AuthorRoles>>> => {
	return useInfiniteQuery<Reponse<SearchResponse<AuthorRoles>>>(
		['authorsRoles', props],
		({ pageParam = 0 }: QueryFunctionContext<QueryKey, number>) =>
			api.call(
				'GET',
				`/admin/authors/roles/?limit=100&page=${pageParam / 100}&sort=${props.sort}`,
			) as Promise<Reponse<SearchResponse<AuthorRoles>>>,
		{
			...options,
			getNextPageParam: (lastPage) => {
				return lastPage.data.nextOffset;
			},
		},
	);
};

export const useAuthorRole = (
	id: number,
	options: UseQueryOptions<Reponse<AuthorRoles>> = {},
): UseQueryResult<Reponse<AuthorRoles>> => {
	return useQuery<Reponse<AuthorRoles>>(
		['authorsRoles', id],
		() => api.call('GET', `/admin/authors/roles/${id}/`) as Promise<Reponse<AuthorRoles>>,
		{
			...options,
			enabled: id > 0,
		},
	);
};
