import { useMutation, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { Reponse } from '../../../utils/request';
import { api } from '../../../_services';
import { AuthorItemListDto, ItemListDto, ValidationState } from '../../../types/global';

export const useAuthorItemsByAuthor = (
	authorId: number,
	options: UseQueryOptions<Reponse<AuthorItemListDto[]>> = {},
): UseQueryResult<Reponse<AuthorItemListDto[]>> => {
	return useQuery<Reponse<AuthorItemListDto[]>>(
		['authors', 'items', authorId],
		() =>
			api.call('GET', `/admin/authors/${authorId}/items/`) as Promise<
				Reponse<AuthorItemListDto[]>
			>,
		{
			...options,
			enabled: authorId > 0,
		},
	);
};

export const useAuthorItemsByItem = (
	itmId: number,
	options: UseQueryOptions<Reponse<AuthorItemListDto[]>> = {},
): UseQueryResult<Reponse<AuthorItemListDto[]>> => {
	return useQuery<Reponse<AuthorItemListDto[]>>(
		['authors', 'items', itmId],
		() =>
			api.call('GET', `/admin/item/${itmId}/authors/`) as Promise<
				Reponse<AuthorItemListDto[]>
			>,
		{
			...options,
			enabled: itmId > 0,
		},
	);
};

interface MutationAuthorItemsUpdUserState {
	itmId: number;
	authorId: number;
	roleId: number;
	state: ValidationState;
}
export const useMutationAuthorItemsUpdUserState = () => {
	return useMutation(async (submitData: MutationAuthorItemsUpdUserState) => {
		return api.call(
			'PUT',
			`/admin/item/${submitData.itmId}/author/${submitData.authorId}/role/${submitData.roleId}/state/`,
			{
				state: submitData.state,
			},
		) as Promise<Reponse<boolean>>;
	});
};
