import Grid from '@mui/material/Grid';
import Counters from 'components/Widgets/Counters';
import React, { Fragment, useEffect, useState } from 'react';
import { dashboardService } from '_services';
import { dashboardResult } from '_services/dashboard.result';
import { FilterItemsConsumerHook } from '_store/filters';
import Wrapper from '../components/Wrapper';
import { useNavigate } from 'react-router-dom';

type DashboardProps = {
	loaded: boolean;
	dashboard: dashboardResult;
};

const Dashboard = () => {
	const [, updFilters] = FilterItemsConsumerHook();
	const navigate = useNavigate();
	const [data, setData] = useState({
		loaded: false,
	} as DashboardProps);

	useEffect(() => {
		if (!data.loaded) {
			var dataTmp: DashboardProps = { ...data, loaded: true };

			dashboardService.dashboard(30).then((dashboard) => {
				dataTmp.dashboard = dashboard;
				setData({ ...dataTmp } as DashboardProps);
			});
		}
	}, [data]);

	function goToItem(typeId: number) {
		updFilters({
			type: 'type',
			value: typeId,
		});
		navigate('/items/');
	}

	return (
		<Fragment>
			{data.dashboard && (
				<>
					<Wrapper sectionHeading='Global'>
						<Grid container spacing={1}>
							<Counters
								name='Objets'
								value={data.dashboard.itemCount}
								valueSince={data.dashboard.itemCountSince}
								icon=''
								onClick={() => navigate('/items/')}
							/>
							<Counters
								name='Utilisateurs'
								value={data.dashboard.userCount}
								valueSince={data.dashboard.userCountSince}
								icon=''
								onClick={() => navigate('/users/')}
							/>
							<Counters
								name='Brasseries'
								value={data.dashboard.breweryCount}
								valueSince={data.dashboard.breweryCountSince}
								icon=''
								onClick={() => navigate('/brewery/')}
							/>
						</Grid>
					</Wrapper>
					<Wrapper sectionHeading='Types'>
						<Grid container spacing={1}>
							{data.dashboard.type.map((type) => {
								var countSince = data.dashboard.typeSince.find(
									(typesince) => type.id == typesince.id,
								)?.count;

								return (
									<Counters
										name={type.name}
										value={type.count}
										valueSince={countSince && countSince > 0 ? countSince : 0}
										icon={type.ico}
										onClick={() => goToItem(type.id)}
									/>
								);
							})}
						</Grid>
					</Wrapper>
				</>
			)}
		</Fragment>
	);
};
export default Dashboard;
