import React, { useState, useEffect } from 'react';
import { itemService } from '_services';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ValidationBloc } from '../../components/ValidationBloc';
import { ValidationState } from '../../types/global';

const Titles = (props) => {
	const [data, setData] = useState({
		loaded: false,
		rows: [],
	});

	useEffect(() => {
		if (!data.loaded) {
			if (props.id && props.id > 0) {
				// chargement des titres
				itemService.getTitles(props.id).then((res) => {
					setData({
						loaded: true,
						rows: res.data,
					});
				});
			}
		}
	}, [data]);

	if (props.id && props.id > 0) {
		function acceptTitle(row) {
			itemService.updTitles(props.id, 'accept', row.usrId, row.title).then((res) => {
				if (props.onTitleUpd) {
					props.onTitleUpd(row.title);
				}
				reloadData();
			});
		}

		function hideTitle(row) {
			itemService.updTitles(props.id, 'hide', row.usrId, row.title).then((res) => {
				reloadData();
			});
		}

		function deleteTitle(row) {
			itemService.updTitles(props.id, 'delete', row.usrId, row.title).then((res) => {
				reloadData();
			});
		}

		function reloadData() {
			setData({
				loaded: false,
				rows: [],
			});
		}

		return (
			<div className='table-wrapper'>
				{data.rows && data.rows.length > 0 && (
					<Table size='small'>
						<TableHead>
							<TableRow>
								<TableCell>Titre</TableCell>
								<TableCell>Usr</TableCell>
								<TableCell>Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.rows.map((row) => (
								<TableRow key={row.name}>
									<TableCell component='th' scope='row'>
										{row.title}
									</TableCell>
									<TableCell>{row.usrId}</TableCell>
									<TableCell>
										<ValidationBloc
											onValidate={() => {
												acceptTitle(row);
												deleteTitle(row);
											}}
											onRefuse={() => {
												hideTitle(row);
											}}
											state={
												row.refused
													? ValidationState.REFUSED
													: ValidationState.UNDEFINED
											}
										/>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				)}
			</div>
		);
	} else {
		return <p>Paramètres invalide.</p>;
	}
};

export default Titles;
