import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { Reponse } from '../../../utils/request';
import { api } from '../../../_services';
import { AuthorRole } from '../../../types/global';

export const useAuthorsRoles = (
	options: UseQueryOptions<Reponse<AuthorRole[]>> = {},
): UseQueryResult<Reponse<AuthorRole[]>> => {
	return useQuery<Reponse<AuthorRole[]>>(
		['authors', 'roles'],
		() => api.call('GET', '/author/roles/') as Promise<Reponse<AuthorRole[]>>,
		{
			...options,
		},
	);
};
