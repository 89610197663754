import {
	QueryFunctionContext,
	QueryKey,
	useInfiniteQuery,
	UseInfiniteQueryOptions,
	UseInfiniteQueryResult,
	useQuery,
	UseQueryOptions,
	UseQueryResult,
} from 'react-query';
import { Reponse } from '../../../utils/request';
import { api } from '../../../_services';
import { SearchResponse } from '../../../types/global';

export interface EditorDto {
	id: number;
	name: string;
	link: string;
	created: string;
	variants: string[];
}

export interface EditorProps {
	sort: 'name-asc' | 'name-desc' | 'created-asc' | 'created-desc' | string;
}

export const useEditors = (
	props: EditorProps,
	options: UseInfiniteQueryOptions<Reponse<SearchResponse<EditorDto>>> = {},
): UseInfiniteQueryResult<Reponse<SearchResponse<EditorDto>>> => {
	return useInfiniteQuery<Reponse<SearchResponse<EditorDto>>>(
		['EditorDto', props],
		({ pageParam = 0 }: QueryFunctionContext<QueryKey, number>) =>
			api.call(
				'GET',
				`/admin/editors/?limit=100&page=${pageParam / 100}&sort=${props.sort}`,
			) as Promise<Reponse<SearchResponse<EditorDto>>>,
		{
			...options,
			getNextPageParam: (lastPage) => {
				return lastPage.data.nextOffset;
			},
		},
	);
};

export const useEditorsSearch = (
	text: string,
	options: UseInfiniteQueryOptions<Reponse<SearchResponse<EditorDto>>> = {},
): UseInfiniteQueryResult<Reponse<SearchResponse<EditorDto>>> => {
	return useInfiniteQuery<Reponse<SearchResponse<EditorDto>>>(
		['EditorDto', 'search', text],
		() =>
			api.call('GET', `/editor/search/?keyword=${text}`) as Promise<
				Reponse<SearchResponse<EditorDto>>
			>,
		{
			enabled: text !== undefined && text.length > 2,

			...options,
			getNextPageParam: (lastPage) => {
				return lastPage.data.nextOffset;
			},
		},
	);
};

export const useEditor = (
	id: number,
	options: UseQueryOptions<Reponse<EditorDto>> = {},
): UseQueryResult<Reponse<EditorDto>> => {
	return useQuery<Reponse<EditorDto>>(
		['EditorDto', id],
		() => api.call('GET', `/admin/editors/${id}/`) as Promise<Reponse<EditorDto>>,
		{
			...options,
			enabled: id > 0,
		},
	);
};
