import { Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import Box from '@mui/material/Box';

const Counters = (props) => {
	return (
		<>
			<Grid item xs={6} md={4} lg={2} onClick={props.onClick}>
				<Card variant={'outlined'} style={{}}>
					<CardContent sx={{ p: 2 }}>
						<Box
							sx={{
								alignContent: 'box-row',
								alignItems: 'start',
							}}
						>
							<Box sx={{ fontWeight: 'bold' }}>
								<Typography variant={'h5'}>{props.name}</Typography>
								<span className='font-size-xxl mt-1'>
									{props.value}
									{props.valueSince > 0 && <> (+{props.valueSince})</>}
								</span>
							</Box>
						</Box>
					</CardContent>
				</Card>
			</Grid>
		</>
	);
};
export default Counters;
