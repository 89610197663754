import React from 'react';
import { useSerieGroup } from './use-series-group';
import Wrapper from '../../../components/Wrapper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Loader from '../../../components/Loader';
import { Box } from '@mui/material';
import PageTitle from '../../../layout-components/PageTitle';
import { Link, useNavigate, useParams } from 'react-router-dom';
import SerieGroupAdd from './SerieGroupAdd';
import { api } from '../../../_services';
import ButtonDialog from '../../../components/Widgets/ButtonDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import SerieGroupUpd from './SerieGroupUpd';

const SerieGroupPage = (props) => {
	const { id } = useParams();
	const idFormatted = Number(id);
	const { data: serieGroup, isLoading, refetch: refetchSerieGroup } = useSerieGroup(idFormatted);

	if (isLoading || !serieGroup?.data) {
		return <Loader />;
	}

	return (
		<>
			<PageTitle titleHeading={serieGroup.data.name} titleDescription='Groupement de série' />

			<Wrapper>
				<Box sx={{ overflow: 'auto' }}>
					<Table size='small' className='table-responsive'>
						<TableHead>
							<TableRow>
								<TableCell>Titre</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{serieGroup?.data.series.map((row) => {
								return (
									<TableRow key={row.id}>
										<TableCell>
											<Link to={`/book/series/${row.id}`}>{row.name}</Link>
										</TableCell>
										<TableCell>
											<ButtonDialog
												onValid={() =>
													api
														.call(
															'DELETE',
															`/admin/series/group/${serieGroup?.data.id}/serie/`,
															{
																serieId: row?.id,
															},
														)
														.then(refetchSerieGroup)
												}
												text='Supprimer'
												color='error'
												startIcon={<DeleteIcon />}
												confirmBtnCancel='Annuler'
												confirmBtnValid='Supprimer'
												confirmTitle='Souhaitez vous supprimer la serie de ce groupement ?'
												confirmDesc="L'action ne sera pas annulable!"
											/>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</Box>
			</Wrapper>

			{serieGroup.data && (
				<SerieGroupAdd serieGroup={serieGroup.data} onChange={refetchSerieGroup} />
			)}

			<SerieGroupUpd serieGroup={serieGroup.data} onUpd={refetchSerieGroup} />
		</>
	);
};

export default SerieGroupPage;
