import React from 'react';
import { Link } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { AuthorRoles } from './use-authors-roles';

interface AuthorsRolesListProps {
	authorRole: AuthorRoles;
}
export default function AuthorRoleList({ authorRole }: AuthorsRolesListProps) {
	return (
		<TableRow>
			<TableCell>
				<Link to={`/book/authorsRoles/${authorRole.id}`}>{authorRole.name}</Link>
			</TableCell>
			<TableCell>{authorRole.created}</TableCell>
		</TableRow>
	);
}
