import Button from '@mui/material/Button';
import React from 'react';
import { ValidationState } from '../types/global';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

interface ValidationBlocProps {
	state: ValidationState;
	onValidate: () => void;
	onRefuse: () => void;
}
export const ValidationBloc = ({ state, onValidate, onRefuse }: ValidationBlocProps) => {
	return (
		<>
			<Button
				onClick={onValidate}
				color='primary'
				variant='outlined'
				size='small'
				sx={{
					minWidth: '20px',
				}}
				disabled={state === ValidationState.ACCEPTED}>
				<CheckIcon />
			</Button>
			<Button
				onClick={onRefuse}
				color={state == ValidationState.REFUSED ? 'primary' : 'error'}
				variant='outlined'
				size='small'
				sx={{
					minWidth: '20px',
				}}
				disabled={state === ValidationState.REFUSED}>
				<CloseIcon />
			</Button>
		</>
	);
};
