import React, { useState } from 'react';
import { useSearchSerie } from './use-series';
import Wrapper from '../../../components/Wrapper';
import Loader from '../../../components/Loader';
import { SeriesDto } from '../../../types/global';
import SerieList from './SerieList';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { api } from '../../../_services';
import { useNavigate } from 'react-router-dom';
import { OutlinedInput } from '@mui/material';

interface SeriePageFusionProps {
	serie: SeriesDto;
}

const SeriePageFusion = ({ serie }: SeriePageFusionProps) => {
	const [serieToFusion, setSerieToFusion] = useState<SeriesDto>();
	const [text, setText] = useState('');
	const navigate = useNavigate();

	const { data: serieSearch, isLoading } = useSearchSerie(text);

	return (
		<Wrapper sectionHeading='Fusion avec une autre série'>
			<OutlinedInput
				fullWidth
				type='text'
				className='form-control'
				onChange={(t) => {
					setText(t.target.value);
				}}
			/>
			{isLoading && <Loader />}
			{serieSearch?.data?.objects
				.filter((s) => s.id != serie.id)
				.map((s) => <SerieList key={s.id} serie={s} onClick={() => setSerieToFusion(s)} />)}

			<Dialog
				open={!!serieToFusion}
				onClose={() => setSerieToFusion(undefined)}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'>
				<DialogTitle id='alert-dialog-title'>Fusion</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Confirmer la fusion ?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setSerieToFusion(undefined)} color='primary'>
						Annuler
					</Button>
					<Button
						onClick={() =>
							api
								.call('PUT', `/admin/series/${serie.id}/fusion/`, {
									destSerieId: serieToFusion?.id,
								})
								.then(() => {
									navigate(`/book/series/${serieToFusion?.id ?? 0}`, {
										replace: true,
									});
								})
						}
						color='primary'>
						Fusioner
					</Button>
				</DialogActions>
			</Dialog>
		</Wrapper>
	);
};

export default SeriePageFusion;
