import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Clear from '@mui/icons-material/Clear';
import Wrapper from 'components/Wrapper';
import ListItem from 'pages/items/List';
import React, { useEffect, useState } from 'react';
import { typeService } from '_services';
import { FilterItemsConsumerHook } from '_store/filters';

var timeout = null;
var search = '';

const Items = (props) => {
	const [filters, updFilters] = FilterItemsConsumerHook();
	const [fetchItem, setFetchItem] = useState({
		loaded: false,
		updcpt: 0,
		types: [],
	});

	useEffect(() => {
		if (!fetchItem.loaded) {
			search = filters.search;
			typeService.getTypes().then((resp) => {
				setFetchItem({
					loaded: true,
					updcpt: 0,
					types: resp,
				});
			});
		}
	}, [fetchItem]);

	function filterChange(evt) {
		updFilters({
			type: 'overrides',
			value: evt.target.value,
		});
	}
	function typeChange(evt) {
		updFilters({
			type: 'type',
			value: evt.target.value,
		});
	}
	function searchChange(evt) {
		search = evt.target.value;
		console.log(search);
		if (timeout != null) {
			clearTimeout(timeout);
		}
		timeout = setTimeout(
			() =>
				updFilters({
					type: 'search',
					value: search,
				}),
			500,
		);
	}
	function resetSearch() {
		updFilters({
			type: 'search',
			value: '',
		});
	}

	return (
		<>
			<Wrapper>
				<FormControl fullWidth variant='outlined' size='small' className='form-filter mb-2'>
					<InputLabel>Types</InputLabel>
					<Select onChange={typeChange} label='Types' value={filters.type}>
						<MenuItem value=''>Tous type</MenuItem>
						{fetchItem.types &&
							fetchItem.types.map((type) => {
								return <MenuItem value={type.id}>{type.name}</MenuItem>;
							})}
					</Select>
				</FormControl>

				<FormControl fullWidth variant='outlined' size='small' className='form-filter mb-2'>
					<InputLabel>Surcharge</InputLabel>
					<Select onChange={filterChange} label='Surcharge' value={filters.overrides}>
						<MenuItem value=''>Toutes surcharge</MenuItem>
						<MenuItem value='title'>Titre Surchagé</MenuItem>
						<MenuItem value='image'>Images Surchargé</MenuItem>
						<MenuItem value='ean'>Ean manquant</MenuItem>
					</Select>
				</FormControl>

				<FormControl fullWidth variant='outlined' size='small' className='form-filter mb-2'>
					<InputLabel>Recherche</InputLabel>
					<OutlinedInput
						label='Search'
						onChange={searchChange}
						defaultValue={filters.search}
						endAdornment={
							<InputAdornment position='end'>
								<IconButton onClick={resetSearch} edge='end'>
									<Clear />
								</IconButton>
							</InputAdornment>
						}
					/>
				</FormControl>

				{fetchItem.loaded && (
					<ListItem
						brewery={0}
						type={filters.type}
						overrides={filters.overrides}
						search={filters.search}
						{...props}
					/>
				)}
			</Wrapper>
		</>
	);
};

export default Items;
