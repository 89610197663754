import React, { useState } from 'react';
import { itemService } from '_services';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { getListImg } from '_helpers';
import InputOnChange from '../../components/InputOnChange';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { ItemDto } from '../../types/global';

interface ItemFusionProps {
	media: ItemDto;
	onAdd: (newId: number) => void;
}
const ItemAddLink = ({ media, onAdd }: ItemFusionProps) => {
	const [open, setOpen] = useState(false);
	const [data, setData] = useState<ItemDto[]>([]);

	return (
		<>
			<Button
				size='small'
				onClick={() => setOpen(true)}
				variant='contained'
				disableElevation
				color='primary'>
				Ajouter
			</Button>
			<Dialog open={open} onClose={() => setOpen(false)} maxWidth={false}>
				<DialogTitle>
					Ajouter un lien sur {media.id} - {media.title}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<InputOnChange
							name='Search'
							type='text'
							onValueChange={async (keyword) => {
								itemService.search(keyword).then((response) => {
									setData(response.objects as ItemDto[]);
								});
							}}
						/>

						<div className='table-wrapper'>
							<Table size='small'>
								<TableBody>
									{data &&
										data.map((r) => {
											if (r.id != media.id) {
												return (
													<TableRow key={r.id}>
														<TableCell>
															{r.images?.map((img) => {
																return (
																	<img
																		height='100'
																		src={getListImg(img.path)}
																	/>
																);
															})}
														</TableCell>
														<TableCell>{r.title}</TableCell>
														<TableCell>
															<Button
																onClick={() => {
																	onAdd(r.id);
																}}
																size='small'
																variant='contained'
																disableElevation
																color='primary'>
																Ajouter
															</Button>
														</TableCell>
													</TableRow>
												);
											}
										})}
								</TableBody>
							</Table>
						</div>
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default ItemAddLink;
