import { api } from '_services';

export const authenticationService = {
	login(login, password) {
		return api.call('POST', '/login/', { login, password }).then((result) => {
			if (result.result) {
				localStorage.setItem('token', result.data.token);
				return result.data;
			} else {
				throw new Error('Fail login');
			}
		});
	},
	logout() {
		localStorage.removeItem('token');
	},
	getToken() {
		return localStorage.getItem('token');
	},
};
