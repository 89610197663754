import React from 'react';
import Wrapper from '../../../components/Wrapper';
import PageTitle from 'layout-components/PageTitle';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { useAuthorsRoles } from './use-authors-roles';
import QueryNextPage from '../../../components/QueryNextPage';
import AuthorRoleList from './AuthorRoleList';
import { useTypedSearchParams } from '../../../utils/useTypedSearchParams';
import { FormProvider, useForm } from 'react-hook-form';
import { useSyncFormWithUrl } from '../../../utils/useSyncFormWithUrl';
import { api } from '../../../_services';

type AuthorsRolesPageParams = {
	sort?: string;
};

type FiltersParams = {
	sort: string;
};
const AuthorsRolesPage = () => {
	const [searchParam] = useTypedSearchParams<AuthorsRolesPageParams>();
	const { data, hasNextPage, isFetchingNextPage, fetchNextPage, refetch } = useAuthorsRoles({
		sort: searchParam.sort ?? 'name-asc',
	});

	const methods = useForm<FiltersParams>({
		mode: 'onChange',
		defaultValues: {
			sort: searchParam.sort ?? 'name-asc',
		},
	});

	useSyncFormWithUrl(methods.watch);

	return (
		<>
			<PageTitle titleHeading='Roles des auteurs' titleDescription='' />

			<Wrapper>
				<FormProvider {...methods}>
					<div>
						<select {...methods.register('sort')}>
							<option value={'name-asc'}>Nom croissant</option>
							<option value={'name-desc'}>Nom décroissant</option>
							<option value={'created-asc'}>Création croissant</option>
							<option value={'created-desc'}>Création décroissant</option>
						</select>
					</div>
				</FormProvider>

				<Table size='small' className='table-responsive'>
					<TableHead>
						<TableRow>
							<TableCell>Titre</TableCell>
							<TableCell>Date</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data?.pages.map((authorRole) =>
							authorRole.data.objects.map((value) => (
								<AuthorRoleList key={value.id} authorRole={value} />
							)),
						)}
					</TableBody>
				</Table>
				<QueryNextPage
					hasNextPage={hasNextPage}
					isFetchingNextPage={isFetchingNextPage}
					fetchNextPage={fetchNextPage}
				/>
			</Wrapper>
		</>
	);
};

export default AuthorsRolesPage;
