import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ButtonDialog = (props) => {
	const text = props.text ?? 'Boutton';
	const color = props.color ?? 'primary';
	const startIcon = props.startIcon ?? null;
	const confirmTitle = props.confirmTitle ?? 'Souhaitez vous confirmer cette action?';
	const confirmDesc = props.confirmDesc ?? '';
	const confirmBtnCancel = props.confirmBtnCancel ?? 'Non';
	const confirmBtnValid = props.confirmBtnValid ?? 'Oui';

	const [openDialog, setOpenDialog] = useState(false);

	function valid() {
		if (props.onValid) {
			props.onValid();
		}
		closeDialog();
	}
	function cancel() {
		if (props.onCancel) {
			props.onCancel();
		}
		closeDialog();
	}

	function closeDialog() {
		setOpenDialog(false);
	}

	return (
		<>
			<Button
				variant='outlined'
				disableElevation
				size='small'
				sx={{
					minWidth: '20px',
				}}
				color={color}
				startIcon={startIcon}
				onClick={() => setOpenDialog(true)}>
				{text}
			</Button>
			{openDialog && (
				<Dialog
					open={openDialog}
					onClose={cancel}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'>
					<DialogTitle id='alert-dialog-title'>{confirmTitle}</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							{confirmDesc}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={cancel} color='primary'>
							{confirmBtnCancel}
						</Button>
						<Button onClick={valid} color='primary' autoFocus>
							{confirmBtnValid}
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</>
	);
};
export default ButtonDialog;
