import { Link } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { EditorDto } from './use-editors';
import React from 'react';

interface EditorsListProps {
	editor: EditorDto;
}
export default function EditorList({ editor }: EditorsListProps) {
	return (
		<TableRow>
			<TableCell>
				<Link to={`/book/editors/${editor.id}`}>{editor.name}</Link>
			</TableCell>
			<TableCell>{editor.created}</TableCell>
		</TableRow>
	);
}
