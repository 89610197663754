import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { api } from '_services';
import { ItemDto, SerieItemAdminDto, SeriesDto } from '../../types/global';
import { Reponse } from '../../utils/request';

export const useItem = (
	itemId: number,
	options: UseQueryOptions<Reponse<ItemDto>> = {},
): UseQueryResult<Reponse<ItemDto>> => {
	return useQuery<Reponse<ItemDto>>(
		['item', 'get', itemId],
		() => api.call('GET', `/admin/item/${itemId}/`) as Promise<Reponse<ItemDto>>,
		{
			...options,
		},
	);
};
export const useItemSerie = (
	itemId: number,
	options: UseQueryOptions<Reponse<SerieItemAdminDto[]>> = {},
): UseQueryResult<Reponse<SerieItemAdminDto[]>> => {
	return useQuery<Reponse<SerieItemAdminDto[]>>(
		['item', 'serie', itemId],
		() =>
			api.call('GET', `/admin/series/item/${itemId}/`) as Promise<
				Reponse<SerieItemAdminDto[]>
			>,
		{
			...options,
		},
	);
};
