import DeleteIcon from '@mui/icons-material/Delete';
import ButtonDialog from 'components/Widgets/ButtonDialog';
import Wrapper from 'components/Wrapper';
import BrewImage from 'pages/brewery/BrewImage';
import BrewMap from 'pages/brewery/BrewMap';
import EditForm from 'pages/brewery/EditForm';
import ListItem from 'pages/items/List';
import React, { useEffect, useState } from 'react';
import { breweryResult, breweryService } from '_services';
import PageTitle from '../../layout-components/PageTitle';
import { useNavigate, useParams } from 'react-router-dom';

type DetailProps = {
	loaded: boolean;
	item?: breweryResult;
};

const Detail = (props) => {
	const navigate = useNavigate();
	const { id } = useParams();
	const idFormatted = Number(id);

	const [data, setData] = useState({
		loaded: false,
	} as DetailProps);

	useEffect(() => {
		if (!data.loaded) {
			if (idFormatted > 0) {
				breweryService.get(idFormatted).then((resp) => {
					setData({ loaded: true, item: resp } as DetailProps);
				});
			} else {
				setData({ loaded: true, item: { id: 0, name: '' } } as DetailProps);
			}
		}
	}, [data]);

	function handleDelete() {
		breweryService.delete(idFormatted).then((res) => {
			navigate('-1');
		});
	}
	function reload() {
		setData({ loaded: false } as DetailProps);
	}

	return (
		<>
			{data.item != null && (
				<>
					<PageTitle
						titleHeading={data.item.name}
						titleDescription="Edition d'une brasserie"
					/>
					<Wrapper sectionHeading='Informations'>
						<EditForm data={data.item} change={reload} />
					</Wrapper>

					<Wrapper sectionHeading='Position'>
						<BrewMap latitude={data.item.latitude} longitude={data.item.longitude} />
					</Wrapper>

					{idFormatted > 0 && (
						<Wrapper sectionHeading='Image'>
							<BrewImage
								id={idFormatted}
								image={data.item.image}
								onChange={() => reload()}
								{...props}
							/>
						</Wrapper>
					)}

					{idFormatted > 0 && (
						<Wrapper sectionHeading='Bières'>
							<ListItem brewery={idFormatted} {...props} />
						</Wrapper>
					)}

					{idFormatted > 0 && (
						<ButtonDialog
							onValid={() => handleDelete()}
							text='Supprimer'
							color='error'
							startIcon={<DeleteIcon />}
							confirmBtnCancel='Annuler'
							confirmBtnValid='Supprimer'
							confirmTitle='Souhaitez vous supprimer cette brasserie ?'
							confirmDesc="L'action ne sera pas annulable!"
						/>
					)}
				</>
			)}
		</>
	);
};

export default Detail;
