import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useAuthorsSearch } from './use-authors-search';
import { useDebouncedCallback } from 'use-debounce';
import { Author, useAuthor, useAuthors } from './use-authors';
import { formatAuthorName } from './AuthorUtiliy';
import { Controller, UseControllerProps, useFormContext, UseFormReturn } from 'react-hook-form';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form/dist/types';

interface AuthorAutocompleteFieldProps<T extends FieldValues> extends UseControllerProps<T> {
	selectedAuthorId?: number;
}

export const AuthorAutocompleteField = <T extends FieldValues>({
	selectedAuthorId,
	...registers
}: AuthorAutocompleteFieldProps<T>) => {
	const [open, setOpen] = useState(false);
	const { data: selectedAuthor } = useAuthor(selectedAuthorId ?? 0);

	const [keyword, setKeyword] = useState('');
	const { data: authors, isLoading } = useAuthorsSearch(keyword);

	const onWatchcallback = useDebouncedCallback(setKeyword, 150, {
		leading: true,
		trailing: true,
	});

	const options = useMemo(() => {
		if (authors?.data?.objects) {
			return authors?.data?.objects;
		}
		if (selectedAuthor?.data) {
			return [selectedAuthor.data];
		}
		return [];
	}, [authors, selectedAuthor]);

	return (
		<Controller
			{...registers}
			render={({ field: { value, onChange } }) => (
				<Autocomplete
					fullWidth
					open={open}
					onOpen={() => {
						setOpen(true);
					}}
					onClose={() => {
						setOpen(false);
					}}
					isOptionEqualToValue={(option, v) => option.id === v.id}
					getOptionLabel={(option) => (option ? formatAuthorName(option) : '')}
					options={options}
					loading={isLoading}
					value={value}
					onChange={(event, value1) => onChange(value1)}
					renderInput={(params) => (
						<TextField
							{...params}
							label='Auteur'
							onChange={(evt) => onWatchcallback(evt.target.value)}
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<React.Fragment>
										{isLoading ? (
											<CircularProgress color='inherit' size={20} />
										) : null}
										{params.InputProps.endAdornment}
									</React.Fragment>
								),
							}}
						/>
					)}
				/>
			)}
		/>
	);
};
