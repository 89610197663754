import React from 'react';
import { getListImg } from '../../../_helpers';
import { ImagesDto } from '../../../types/global';

interface ImageListProps {
	height: number;
	width: number;
	image?: ImagesDto;
}

export default function ImageList({ image, height, width = height }: ImageListProps) {
	if (!image) {
		return null;
	}

	return <img src={getListImg(image.path)} width={width} height={height} alt='' />;
}
