import React, { useMemo, useState } from 'react';
import Wrapper from '../../../components/Wrapper';
import { useAuthors } from './use-authors';
import OutlinedInput from '@mui/material/OutlinedInput';
import { api } from '../../../_services';
import FormControl from '@mui/material/FormControl';
import { Link } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import PageTitle from 'layout-components/PageTitle';
import { useTypedSearchParams } from '../../../utils/useTypedSearchParams';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useSyncFormWithUrl } from '../../../utils/useSyncFormWithUrl';
import { useSearchSerieInfinite, useSeries } from '../series/use-series';
import { useAdminAuthorsSearch, useAuthorsSearch } from './use-authors-search';

type AuthorsPageParams = {
	override?: string;
	search?: string;
	sort?: string;
	page?: string;
};

type FiltersParams = {
	override: boolean;
	search: string;
	sort: string;
	page: number;
};

const limit = 100;

const useRequest = (params: FiltersParams) => {
	const requestSearch = useAdminAuthorsSearch(params.search);
	const requestNormal = useAuthors({
		...params,
		limit,
	});

	if (params.search && params.search.length > 2) {
		return requestSearch;
	}

	return requestNormal;
};

const AuthorsPage = () => {
	const navigate = useNavigate();

	const [searchParam] = useTypedSearchParams<AuthorsPageParams>();

	const methods = useForm<FiltersParams>({
		mode: 'onChange',
		defaultValues: {
			sort: searchParam.sort ?? '',
			override: Boolean(searchParam.override ?? false),
			search: searchParam.search ?? '',
			page: Number(searchParam.page ?? 0),
		},
	});

	const { data: authors, isLoading } = useRequest({
		...methods.getValues(),
	});

	useSyncFormWithUrl(methods.watch);

	const columns = useMemo(
		() => [
			{
				field: 'id',
				headerName: 'ID',
				width: 80,
				renderCell: (p) => {
					return (
						<Link
							href={'/book/authors/' + p.row.id}
							onClick={() => navigate('/book/authors/' + p.row.id)}>
							{p.row.id}
						</Link>
					);
				},
			},
			{
				field: 'name',
				headerName: 'Nom',
				flex: 0.2,
				renderCell: (p) => {
					return (
						<FormControl fullWidth>
							<OutlinedInput
								label='Name'
								type={'text'}
								onBlur={(event) =>
									void api.call(
										'PUT',
										`/admin/authors/${p.row.id}/updName/`,
										event.target.value,
									)
								}
								defaultValue={p.row.name}
							/>
						</FormControl>
					);
				},
			},
			{
				field: 'lastname',
				headerName: 'Prénom',
				flex: 0.2,
				renderCell: (p) => {
					return (
						<FormControl fullWidth>
							<OutlinedInput
								label='Prénom'
								type={'text'}
								onBlur={(event) =>
									void api.call(
										'PUT',
										`/admin/authors/${p.row.id}/updLastname/`,
										event.target.value,
									)
								}
								defaultValue={p.row.lastname}
							/>
						</FormControl>
					);
				},
			},
			{
				field: 'surname',
				headerName: 'Surnom',
				flex: 0.2,
				renderCell: (p) => {
					return (
						<FormControl fullWidth>
							<OutlinedInput
								label='Surnom'
								type={'text'}
								onBlur={(event) =>
									void api.call(
										'PUT',
										`/admin/authors/${p.row.id}/updSurname/`,
										event.target.value,
									)
								}
								defaultValue={p.row.surname}
							/>
						</FormControl>
					);
				},
			},
			{ field: 'variants', headerName: 'Variantes', flex: 0.1 },
			{ field: 'created', headerName: 'Date', flex: 0.1 },
		],
		[],
	);

	const dataDisplay = authors?.data?.objects ?? [];
	return (
		<>
			<PageTitle titleHeading='Auteurs' titleDescription='' />

			<Wrapper>
				<FormProvider {...methods}>
					<input type={'hidden'} {...methods.register('sort')} />
					<input type={'hidden'} {...methods.register('page')} />
					<div>
						<label>
							<input type='checkbox' {...methods.register('override')} /> &nbsp;Voir
							que les surcharges
						</label>
					</div>
					OU
					<div>
						<label>
							Recherche
							<input
								type='text'
								className='form-control'
								{...methods.register('search')}
							/>
						</label>
					</div>
				</FormProvider>
				<DataGrid
					autoHeight
					rows={dataDisplay}
					columns={columns}
					initialState={{
						pagination: {
							paginationModel: { pageSize: limit, page: methods.getValues('page') },
						},
					}}
					rowCount={authors?.data.count ?? 0}
					getRowHeight={() => 'auto'}
					paginationModel={{
						page: methods.getValues('page'),
						pageSize: limit,
					}}
					paginationMode='server'
					onPaginationModelChange={(model) => methods.setValue('page', model.page)}
					onSortModelChange={(newSortModel) => {
						if (newSortModel.length > 0) {
							const tmp = newSortModel[0];
							methods.setValue('sort', `${tmp.field}-${tmp.sort}`);
						}
					}}
					loading={isLoading}
				/>
			</Wrapper>
		</>
	);
};

export default AuthorsPage;
